import Header from "../../../components/client/common/Header";
import Footer from "../../../components/client/common/Footer";
import Banner from "../common/Banner";

const SearchContainer = ({ Title, Subtitle,display_map=true, children}) => {
  return (
    <>
      <Header />
      <Banner Title={Title} Subtitle={Subtitle} />
      {children}
      <Footer /> 
    </>
  );
};

export default SearchContainer;
