import React from 'react'
import "../../../../../../../styles/client/clinic/GridTimetable.scss"

import { useState } from 'react'
import Slot from './Slot'


 const Workday = ({ weekday='Lundi',dayoff=true,daydetail,disableday,enableday,providerId}) => {
  const [daystatus,setdayStatus] = useState(dayoff)


  const DayToDisplay = (value) => {
    const weekdays = ['Dimanche','Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi','Dimanche'];
    return weekdays[value];
  };
  
  const dayAvailability = (e)=>{
    setdayStatus(e.target.checked);
    (e.target.checked)?enableday(daydetail.dayOfWeek):disableday(daydetail.dayOfWeek);
  }

  return (
        <div className="column">
         <div className="day"> 
            <div className="checkbox-global">
                    <div className="checkbox_box">
                    <input id={`checkBoxth${weekday}`} defaultChecked={dayoff} onChange={dayAvailability} type="checkbox" hidden />
                    <label htmlFor={`checkBoxth${weekday}`}>
                        <div className="disk"></div>
                    </label>
                    </div>
            </div>
            <span>{DayToDisplay(weekday)}</span>
             </div>

              {daydetail?.slots.map((slot,index)=>
                <Slot daystatus={daystatus} weekday={weekday} providerId={providerId} slotStatus ={slot.status}  slot={slot}/>
               )}
        </div> 
  )
}
export default Workday