import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import tick from "../../../../assets/Icons/Vector_pupple.png";
import { AXIOS_POST } from "../../../../config/axios";
import { POST_GOOGLE_LOGIN, POST_REGISTER_GOOGLE_URL, POST_REGISTER_URL } from "../../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Translate from "../../../../config/Translate";
import Loader from "../../../../helper/Loader/Loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/LanguageSlice";
import FormErrors from "../../../../helper/Message/FormErrors";
import "../../../../styles/client/account/MainRegister.scss";
import Google from '../../../../assets/Icons/logo googleg 48dp.png';
import { authPop, loginWithApple, loginWithFacebook } from "../../../../firebase";
import ErrorToast from "../../../../helper/Message/ErrorToast";
import SuccessToast from "../../../../helper/Message/SuccessToast";
import { Visibility, VisibilityOff, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const ClinicRegister = () => {
  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const [passwordvisible, setpasswordvisible] = useState(false);
  const [cpasswordvisible, setcpasswordvisible] = useState(false);

  let accountCreated =
    languageValue === "En"
      ? "Account created successful"
      : "Compte créé avec succès";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {  
    data.type = 1;
    data.role = 40;
    data.latitude = 80;
    data.longitude = 120;
    setIsSubmited(true);
    AXIOS_POST(POST_REGISTER_URL, data, "", "")
      .then((res) => {
        localStorage.setItem("email", data.email);
        if (res.data.message) {
          SuccessToast(res.data.message)
        } else {
          SuccessToast(accountCreated)
          setTimeout(() => navigate("/vetverify", { replace: true }), 3000);
        }
        setIsSubmited(false);
      })
      .catch((error) => {
        ErrorToast(error);
        setIsSubmited(false);
      });
  };


  const googleAuth=async ()=>{
    const user = await authPop();
    let data ={};
    data.type = 1;
    data.role = 40;
    setIsSubmited(true);
    AXIOS_POST(POST_REGISTER_GOOGLE_URL+`?type=${data.type}&role=${data.role}`, data, user.accessToken, "")
      .then((res) => { 
          SuccessToast(accountCreated)
          setTimeout(
            () => navigate("/login", { replace: true }),
            0
          );
        setIsSubmited(false);
      })
      .catch((error) => {
        ErrorToast(error);
        setIsSubmited(false);
      });
  }

  return (
    <div className="register-container">
      <ToastContainer />
      <div className="register-inner-wrapper" data-aos="zoom-out-up">
        <div className="top-form-title">
          <Translate Word={"page.join"} />
          <span className="span-colored text_purple">oneclinic.vet</span>
        </div>
        <h1 className="lg-size text_purple">
        Inscription vétérinaire
        </h1>
        <p className="text-center p-description">
        <strong>Inscrivez-vous dès maintenant et simplifiez-vous la vie !</strong>
          <br />
           Profitez d'une solution simple, intuitive et rapide pour vous connecter à vos clients et optimiser votre temps.
          Découvrez notre plateforme innovante et notre nouvelle offre sans abonnement ni engagement, conçue spécialement pour la profession vétérinaire.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-container form-container"
        >
          <div className="input-container">
            <div className="flex-container input-container">

              <div className="input-holder">
                <label className="text_purple">
                  <Translate Word={"page.register.email"} />
                </label>
                <input
                  className="border_purple"
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Votre e-mail*"
                />
                {errors.email?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.email.error"} />
                  </FormErrors>
                )}
              </div>

              <div className="input-holder">
                <label className="text_purple">
                  <Translate Word={"page.register.cemail"} />
                </label>
                <input
                  className="border_purple"
                  type="email"
                  {...register("confirmEmail", {required: true, validate: (val) => {
                    if (watch("email") != val) {
                      return "Your passwords do no match";
                    }
                  } })}
                  placeholder="Confirmez votre e-mail*"
                />
                {errors.confirmEmail?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.email.error"} />
                  </FormErrors>
                )}
                {errors.confirmEmail?.type === "validate" && (
                  <FormErrors>
                    <Translate Word={"page.confirm.email.error"} />
                  </FormErrors>
                )}

              </div>
            </div>

            <div className="flex-container input-container">
              <div className="input-holder">
                <label className="text_purple">
                  <Translate Word={"page.register.password"} />
                </label>
                <div className="vb_item border_purple">
                <input
                  type={passwordvisible ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
                  })}
                  placeholder="Choisissez votre mot de passe*"
                />
                 <div className="vb_icon svg_purple" onClick={()=>setpasswordvisible(!passwordvisible)}>
                  {passwordvisible ? <VisibilityOutlined />:<VisibilityOffOutlined/>}
                </div>
                </div>
                {errors.password?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.password.error"} />
                  </FormErrors>
                )}
                {errors.password?.type === "pattern" && (
                  <FormErrors>
                    <Translate Word={"page.register.passwordPatern.error"} />
                  </FormErrors>
                )}
              </div>
              <div className="input-holder">
                <label className="text_purple">
                  <Translate Word={"page.register.cpassword"} />
                </label>
                <div className="vb_item border_purple">
                <input
                  className="border_purple"
                  type={cpasswordvisible ? "text" : "password"}
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  placeholder="Confirmez votre mot de passe*"
                />
                <div className="vb_icon svg_purple" onClick={()=>setcpasswordvisible(!cpasswordvisible)}>
                  {cpasswordvisible ? <VisibilityOutlined />:<VisibilityOffOutlined/>}
                </div>
                </div>
                
                {errors.confirmPassword?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.password.error"} />
                  </FormErrors>
                )}
                {errors.confirmPassword?.type === "validate" && (
                  <FormErrors>
                    <Translate Word={"page.register.cpmatch.error"} />
                  </FormErrors>
                )}
              </div>
            </div>

            <div className="flex-container input-container">
              <div className="input-holder">
                <label className="text_purple">
                  <span className="main-color text_purple">Politique de confidentialité*</span>
                </label>
                <div className="terms-check">
                <div className="checkbox-group">
                <label className="checkbox_wrapper border_purple" htmlFor="checkbox1">
                  <div className="pet_checkbox border_purple"></div>
                  <span></span>
                  <input
                    type="checkbox"
                    {...register("terms", { required: true })}
                    className="pet_hidden_check border_purple"
                    id="checkbox1"
                    hidden
                  />
                  <img src={tick} alt="" />
                </label>
              </div>
                  <span>
                  Pour créer un compte, je comprends que je suis tenu(e) de respecter les Conditions Générales d'Utilisation et la Politique de Confidentialité.
                  </span>
                </div>
                {errors.terms && (
                  <FormErrors>
                    <Translate Word={"page.register.terms.error"} />
                  </FormErrors>
                )}
              </div>
            </div>

            {isSubmited ? (
              <div className="submit-button">
                <button type="button" className="bg_purple_strong">
                  <Loader color="white"/>
                </button>
              </div>
            ) : (
              <div className="submit-button">
                <button className="bg_purple_strong">
                  S’inscrire
                </button>
              </div>
            )}
          </div>
        </form>
        <div className="authbtn-group">
         {/* <button className="other-login other-login1" onClick={facebookAuth}> <img src={Facebook} alt="" />Se connecter avec Facebook</button> */}
         {/* <button className="other-login other-login2" onClick={googleAuth}> <img src={Google} alt="" />Se connecter avec Google</button> */}
         {/* <button className="other-login other-login3" onClick={appleAuth}> <img src={Apple} alt="" />Se connecter avec Apple</button> */}
        </div>
  
      </div>
    </div>
  );
};

export default ClinicRegister;
