import React, { useEffect, useRef } from "react";
import { GeneralCard } from "./GeneralCard";
import { selectSearchResult } from "../../../features/SearchSlice";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../helper/Loader/Loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SearchCard = ({ ClinicIdSearch }) => {
  const searchData = useSelector(selectSearchResult).payload;

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {searchData?.length > 0 ? (
        searchData.map((item, index) => (
          <GeneralCard
            ClinicIdSearch={ClinicIdSearch}
            item={item}
            index={index}
          />
        ))
      ) : (
        <div>
          {/* <Loader size={17}/> */}
          <div style={{ display: "flex", gap: 8, marginBottom: "50px" }}>
            <div style={{ flex: 3 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
            <div style={{ flex: 1 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
          </div>
          <div style={{ display: "flex", gap: 8, marginBottom: "50px" }}>
            <div style={{ flex: 3 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
            <div style={{ flex: 1 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
          </div>
          <div style={{ display: "flex", gap: 8, marginBottom: "50px" }}>
            <div style={{ flex: 3 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
            <div style={{ flex: 1 }}>
              <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                <Skeleton height={500} />
              </SkeletonTheme>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
