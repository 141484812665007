import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "react-reveal/Fade";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useEffect } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { GET_PETS_URL } from "../../../helper/Url";
import { set } from "react-hook-form";
import { ReactComponent as PetsIcons } from '../../../assets/Icons/paw-icon.svg'; 
import { selectAnimalEdited } from "../../../features/AnimalsListChange";
import { selectUserInfo } from "../../../features/UserInfoSlice";

const Sidebar = ({ changeBannerInfo }) => {
  const [accSubmenu, setaccSubmenu] = useState(false);
  const [infoSubmenu, setinfoSubmenu] = useState(false);
  const [animalSubmenu, setanimalSubmenu] = useState(false);
  const AnimalEdited = useSelector(selectAnimalEdited);
  const navigate = useNavigate(); 
  const languageValue = useSelector(selectLanguage);
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();
  const location = useLocation();
  const { id } = useParams();
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const token = localStorage.getItem("user_token");
  const [petsData,setpetsData] = useState([]);
  const selectUserinfoData = useSelector(selectUserInfo);


  const getAnimals = ()=>{
   AXIOS_GET(GET_PETS_URL,token)
   .then(res=>{
   setpetsData(res.data)
   })
   .catch((error)=>{
    
   })

  }
  useEffect(()=>{
    getAnimals();
  },[location,AnimalEdited])

  const menuNavigate = (path, title, subtitle) => {
    navigate(path);
    setTitle(title);
    setSubtitle(subtitle);
  };
  useEffect(() => {
    location.pathname === "/customer/dashboard" &&
    changeBannerInfo(
        languageValue === "En" ? "MON ESPACE" : "MON ESPACE",
        languageValue === "En" ? "Votre compte en un clic" : "Votre compte en un clic"
      );
    location.pathname === "/customer/dashboard/account" &&
    changeBannerInfo(
      languageValue === "En" ? "MON ESPACE" : "MON ESPACE",
      languageValue === "En" ? "Votre compte en un clic" : "Votre compte en un clic"
    );
    location.pathname === "/customer/dashboard/myclinicinfo" &&
    changeBannerInfo(
      languageValue === "En" ? "MON ESPACE" : "MON ESPACE",
      languageValue === "En" ? "Votre compte en un clic" : "Votre compte en un clic"
    );
    (location.pathname === "/customer/dashboard/animals" || id > 0) &&
    changeBannerInfo(
      languageValue === "En" ? "MON ESPACE" : "MON ESPACE",
      languageValue === "En" ? "Votre compte en un clic" : "Votre compte en un clic"
    );
    location.pathname === "/customer/dashboard/rendezvous" &&
    changeBannerInfo(
      languageValue === "En" ? "MON ESPACE" : "MON ESPACE",
      languageValue === "En" ? "Votre compte en un clic" : "Votre compte en un clic"
    );
  }, [location.pathname, languageValue]);

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };



  // Get the pathname from the location
  const { pathname } = location;

  // Split the pathname into parts based on '/'
  const pathParts = pathname.split('/');

  // Get the last part of the pathname
  const lastPart = pathParts[pathParts.length - 1];
  const lastPart2 = pathParts[pathParts.length - 2];

  const setaccountoff = ()=>{
    setaccSubmenu(!accSubmenu)
    setinfoSubmenu(false)
    setanimalSubmenu(false)
  }

  const setinfooff = ()=>{
    setinfoSubmenu(!infoSubmenu)
    setaccSubmenu(false)
    setanimalSubmenu(false)
  }

  const setanimaloff = ()=>{
    setanimalSubmenu(!animalSubmenu)
    setaccSubmenu(false)
    setinfoSubmenu(false)
  }
  const setalloff = ()=>{
    setanimalSubmenu(false)
    setaccSubmenu(false)
    setinfoSubmenu(false)
  }


  return (
    <div className="sidebar-container customer-container">
      <div className="sticky_container">
        <h1>{selectUserinfoData?.firstname?selectUserinfoData.firstname:userFirstname}{" "}{selectUserinfoData?.lastname?selectUserinfoData.lastname:userLastname}</h1>
        <NavLink
        to={"/customer/dashboard/summary"}
        onClick={setalloff}
        className="menu-button">
          <div
            className="menu"
            onClick={() => {
              menuNavigate(
                "/customer/dashboard/summary",
                languageValue === "En" ? "MON ESPACE" : "MON ESPACE"
              );
            }}
          >
            <div></div>
            <h4 className="h4-exp">
              {languageValue === "En" ? "MON ESPACE" : "MON ESPACE"}
            </h4>
            <div></div>
          </div>
        </NavLink>

        <NavLink
        to={"/customer/dashboard/account"}
        onClick={setalloff}
        className="menu-button">
          <div
            className="menu"
            onClick={() => {
              menuNavigate(
                "/customer/dashboard/account",
                languageValue === "En" ? "MON COMPTE" : "MON COMPTE"
              );
            }}
          >
            <div></div>
            <h4 className="h4-exp">
              {languageValue === "En" ? "MON COMPTE" : "MON COMPTE"}
            </h4>
            <div></div>
          </div>
        </NavLink>

        {/* {lastPart  != 'account'
        &&  <div className="menu-button">
        <div className="menu" onClick={setaccountoff}>
          <div></div>
          <h4> {languageValue === "En" ? "MON COMPTE" : "MON COMPTE"}</h4>
          {accSubmenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {accSubmenu && (
          <div className="sub-menu">
            <Link to="account">
              <Fade bottom>
                {languageValue === "En" ? "MON COMPTE" : "MON COMPTE"}
              </Fade>
            </Link>
          </div>
        )}
      </div>} */}
       

        {/* {lastPart  === 'account' && (
           <div className="menu-button active-menu" >
           <div className="menu" onClick={() => setaccSubmenu(!accSubmenu)}>
             <h4 className="h4-active"> {languageValue === "En" ? "MON COMPTE" : "MON COMPTE"}</h4>
           </div>
           {accSubmenu && (
             <div className="sub-menu">
               <Link to="account">
                 <Fade bottom>
                   {languageValue === "En" ? "MON COMPTE" : "MON COMPTE"}
                 </Fade>
               </Link>
             </div>
           )}
         </div>
          )} */}


        <NavLink
        to={"/customer/dashboard/customerclinicinfo"}
        onClick={setalloff}
        className="menu-button">
          <div
            className="menu"
            onClick={() => {
              menuNavigate(
                "/customer/dashboard/customerclinicinfo",
                languageValue === "En" ? "MES INFORMATIONS" : "MES INFORMATIONS"
              );
            }}
          >
            <div></div>
            <h4 className="h4-exp">
              {languageValue === "En" ? "MES INFORMATIONS" : "MES INFORMATIONS"}
            </h4>
            <div></div>
          </div>
        </NavLink>

        {/* {lastPart  != 'customerclinicinfo' &&
          <div className="menu-button">
          <div className="menu" onClick={setinfooff}>
            <div></div>
            <h4>
              {languageValue === "En" ? "MES INFORMATIONS" : "MES INFORMATIONS"}
            </h4>
            {infoSubmenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {infoSubmenu && (
            <div className="sub-menu">
              <Link to="customerclinicinfo">
                <Fade bottom>
                  {languageValue === "En"
                    ? "MES INFORMATIONS"
                    : "MES INFORMATIONS"}
                </Fade>
              </Link>
            </div>
          )}
        </div>
        }
        
        {lastPart  === 'customerclinicinfo' && 
         <div className="menu-button active-menu">
         <div className="menu" onClick={setinfooff}>
           <h4 className="h4-active">
             {languageValue === "En" ? "MES INFORMATIONS" : "MES INFORMATIONS"}
           </h4>
         </div>
         {infoSubmenu && (
           <div className="sub-menu">
             <Link to="customerclinicinfo">
               <Fade bottom>
                 {languageValue === "En"
                   ? "MES INFORMATIONS"
                   : "MES INFORMATIONS"}
               </Fade>
             </Link>
           </div>
         )}
       </div>
        } */}



        {lastPart  != 'animals' && lastPart2 != 'animals' &&
           <div className="menu-button">
           <div
             className="menu"
             onClick={setanimaloff}
           >
             <div></div>
             <h4>{languageValue === "En" ? "MES ANIMAUX" : "MES ANIMAUX"}</h4>
             {animalSubmenu ? (
               <KeyboardArrowUpIcon />
             ) : (
               <KeyboardArrowDownIcon />
             )}
           </div>
           {animalSubmenu && (
             <div className="sub-menu">
               <Link to="animals">
                 <Fade bottom>
                   {languageValue === "En" ? "All" : "Tous"}
                 </Fade>
               </Link>
               {petsData.length>0 ?
               petsData.map(pet=>
                 <Link to={`animals/${pet._id}`}>
                 <Fade bottom> {pet.name}</Fade>
                </Link> 
                 ):''
               }
             
             </div>
           )}
         </div>
        }
       

        {(lastPart  === 'animals' || lastPart2 === 'animals') &&
        
        <div className="menu-button active-menu">
          <div
            className="menu"
            onClick={setanimaloff}
          >
            <h4 className="h4-active">{languageValue === "En" ? "MES ANIMAUX" : "MES ANIMAUX"}</h4>
          </div>
          {animalSubmenu && (
            <div className="sub-menu">
              <Link to="animals">
                 <Fade bottom>
                   {languageValue === "En" ? "All" : "Tous"}
                 </Fade>
               </Link>
              {petsData.length>0 ?
              petsData.map(pet=>
                <Link to={`animals/${pet._id}`}>
                <Fade bottom>{lastPart == pet._id ? <div className="side-link"><PetsIcons /> {pet.name}</div> : <div className="side-link">{pet.name}</div> }</Fade>
               </Link>
                ):''
              }
            
            </div>
          )}
        </div>
        }
 

        <NavLink 
        to={"/customer/dashboard/rendezvous"}
        className="menu-button"
        onClick={setalloff}
        >
          <div
            
            className="menu"
            onClick={() => {
              menuNavigate(
                "/customer/dashboard/rendezvous",
                languageValue === "En" ? "MES RENDEZ-VOUS" : "MES RENDEZ-VOUS"
              );
            }}
          >
            <div></div>
            <h4 className="h4-exp">
              {languageValue === "En" ? "MES RENDEZ-VOUS" : "MES RENDEZ-VOUS"}
            </h4>
            <div></div>
          </div>
        </NavLink>


        <div className="logout" onClick={logOut}>
          <LogoutIcon className="lg_svg" />{" "}
          <h4>{languageValue === "En" ? "Logout" : "Se déconnecter"}</h4>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
