import React, { useEffect, useState } from "react";
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from "../../../config/axios";
import {
  GET_SINGLE_CLINIC_URL,
  UPDATE_CLINIC_INFO_URL,
  googleMapsApiKey,
} from "../../../helper/Url";
import { useNavigate } from "react-router-dom";
import Loader from "../../../helper/Loader/Loader";
import PlaceAutocomplete from "../common/Autocomplete";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { ReactComponent as AddRed } from "../../../assets/Icons/AddRed.svg";
import { ToastContainer } from "react-toastify";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { Base_Url } from "../../../config/variables";
import { Form, set, useForm } from "react-hook-form";
import FormErrors from "../../../helper/Message/FormErrors";
import axios from "axios";
import PhoneField from "../../common/PhoneField";

const CompanyPreInfo = () => {
  const token = localStorage.getItem("user_token");
  const id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ latitude: 0, longitude: 0 });
  const [ischeckbox, setischeckbox] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [lat, setlat] = useState(47.46171229999999);
  const [lng, setlng] = useState(8.5508703);
  const [place, setplace] = useState("");
  const [base64String, setbase64String] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    watch,
    formState: { errors: errors2 },
  } = useForm();

  // watch adress, pays, city, postalCode

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const onhandleSubmit1 = (data) => {
    setLoading(true);
    data.telephone = telephone;
    AXIOS_PATCH(UPDATE_CLINIC_INFO_URL + `${id}`, data, token)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("user_firstname", res.data.firstname);
        localStorage.setItem("user_lastname", res.data.lastname);
        setTimeout(() => {
          navigate("/clinic/dashboard/summary");
        }, 100);
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      });
  };

  const getClicInfo = () => {
    AXIOS_GET(GET_SINGLE_CLINIC_URL + `${id}`, token)
      .then((res) => {
        setlng(res.data.geolocation?.coordinates[0]);
        setlat(res.data.geolocation?.coordinates[1]);
        setplace(res.data.otherInfo);
        setFormData({ ...formData, ...res.data });
        setischeckbox(formData?.publicTransport);
        // set value for only field in form one of Informations de la personne de contact
        if (res.data) {
          setValue(
            "firstname",
            res.data.firstname === " " ? "" : res.data.firstname
          );
          setValue(
            "lastname",
            res.data.lastname === " " ? "" : res.data.lastname
          );
          setValue("civility", res.data.civility);
          setValue("title", res.data.title);
          setValue("email", res.data.email);
          setValue("telephone", res.data.telephone);
        }
        // set value for only field in form two of Informations sur l’établissement
        if (res.data) {
          setValue2("facilityName", res.data.facilityName);
          setValue2("address", res.data.address);
          setValue2("postalCode", res.data.postalCode);
          setValue2("city", res.data.city);
          setValue2("country", res.data.country);
          setValue2("publicTransport", res.data.publicTransport);
          setValue2("parking", res.data.parking);
          setValue2("otherInfo", res.data.otherInfo);
          setValue2("cachemail", res.data.cachemail);
          setValue2("telephone1", res.data.telephone1);
          // setValue2("telephone2", res.data.telephone2);
          setValue2("facebookLink", res.data.facebookLink);
          setValue2("instagramLink", res.data.instagramLink);
          setValue2("linkedinLink", res.data.linkedinLink);
          setValue2("twitterLink", res.data.twitterLink);
          setValue2("otherDescription", res.data.otherDescription);
        }
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getClicInfo();
  }, []);

  // get geolocation when adress, pays, city, postalCode change

  const watchedAddress = watch("address");
  const watchedPostalCode = watch("postalCode");
  const watchedCity = watch("city");
  const watchedCountry = watch("country");

  const fetchGoogleMapsData = async () => {
    try {
      const addressString = `${watchedAddress},${watchedCity},${watchedPostalCode},${watchedCountry}`;
      const encodedAddress = encodeURIComponent(addressString);

      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleMapsApiKey}`
      );

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setlat(lat);
        setlng(lng);
      } else {
        console.error("Google Maps Geocoding did not return any results.");
      }
    } catch (error) {
      console.error("Error fetching Google Maps Geocoding data:", error);
    }
  };

  useEffect(() => {
    if (watchedAddress && watchedCountry && watchedCity && watchedPostalCode) {
      fetchGoogleMapsData();
    }
  }, [watchedAddress, watchedCountry, watchedCity, watchedPostalCode]);

  return (
    <div className="main-container">
      <ToastContainer />
      <div className="wrapper">
        <div className="Intro-title">
          <h1>Bienvenue </h1>
          <p className="text_purple">Mes informations en un clic</p>
        </div>
        <div className="display-holder">
          <form className="infor-form" onSubmit={handleSubmit(onhandleSubmit1)}>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Prénom*
              </label>
              <input
                type="text"
                name="firstname"
                onChange={handleInputChange}
                placeholder="Prénom*"
                {...register("firstname", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.firstname && (
                <FormErrors>Veuillez entrer votre prénom</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Nom*
              </label>
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
                placeholder="Nom*"
                {...register("lastname", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.lastname && (
                <FormErrors>Veuillez entrer votre nom</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Civilité*
              </label>
              <select
                id=""
                name="civility"
                disabled
                onMouseOver={(e) => (e.target.disabled = false)}
                onChange={handleInputChange}
                {...register("civility", { required: true })}
                className="border_purple margin-bottom-13"
              >
                <option value="" selected disabled hidden>
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
              {errors.civility && (
                <FormErrors>Veuillez entrer votre civilité</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Titre*
              </label>
              <select
                id=""
                name="title"
                disabled
                onMouseOver={(e) => (e.target.disabled = false)}
                onChange={handleInputChange}
                {...register("title", { required: true })}
                className="border_purple margin-bottom-13"
              >
                <option value="" selected disabled hidden>
                  Titre*
                </option>
                <option value="Dr">Dr.</option>
                <option value="Mme">Mme.</option>
                <option value="Mlle">Mlle.</option>
                <option value="M">M.</option>
                <option value="Me">Me.</option>
                <option value="Pr">Pr.</option>
              </select>
              {errors.title && (
                <FormErrors>Veuillez entrer votre titre</FormErrors>
              )}
            </div>

            <div className="input-container">
              <label className="text_purple" htmlFor="">
                E-mail*
              </label>
              <input
                type="email"
                name="email"
                readOnly
                placeholder="E-mail*"
                {...register("email", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.email && (
                <FormErrors>Veuillez entrer votre email</FormErrors>
              )}
            </div>
            <div className="input-container margin-bottom-13">
              <label className="text_purple" htmlFor="">
                Téléphone*
              </label>
              {/* <div className="input-icon border_purple flex-container margin-bottom-13">
              <AddRed />
            <input
              type="number"
              placeholder="41XXXXXXXXX"
              name="telephone"
              onChange={handleInputChange}
              {...register("telephone", {
                required: "Le téléphone est requis",
                pattern: {
                  value: /^41\d{9}$/,
                  message:
                    "Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)",
                },
              })}
              className="border_purple margin-bottom-13"
            />
            </div> */}
              <PhoneField
                containerStyle={{
                  width: "100%",
                  height: "52px",
                  marginBottom: "20px",
                }}
                buttonStyle={{ borderColor: "#2cb5b2" }}
                inputStyle={{
                  width: "100%",
                  height: "52px",
                  borderColor: "#2cb5b2",
                }}
                placeholder="Votre téléphone*"
                telephone={telephone}
                settelephone={setTelephone}
              />
              {telephone === "" && (
                <FormErrors>Le téléphone est requis</FormErrors>
              )}
            </div>

            <div className="input-container btn-container">
              {loading ? (
                <button type="button" className="bg_purple_strong">
                  <Loader color="#ffffff"></Loader>
                </button>
              ) : (
                <button className="bg_purple_strong border_purple">
                  VALIDER LES INFORMATIONS
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyPreInfo;
