import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeContainer from "../../../components/client/container/HomeContainer";
import { selectLanguage } from "../../../features/LanguageSlice";
import "../../../styles/search/General.scss";
import { SearchCard } from "./SearchCard";
import { useLocation } from "react-router-dom";
import {
  selectSearchResult,
  setSearchedClinics,
} from "../../../features/SearchSlice";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SEARCH_CLINICS_NEAREST_URL } from "../../../helper/Url";

export const General = () => {
  const languageValue = useSelector(selectLanguage);
  const [place, setPlace] = React.useState("");
  const searchData = useSelector(selectSearchResult).payload;
  const bookPage = useRef();

  useEffect(() => {
    if (window.innerWidth > 800) {
      window.scrollTo({ top: 500, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 325, behavior: "smooth" });
    }
  }, [searchData]);

  // Get the location object using the useLocation hook
  const location = useLocation();

  // Parse the search string to get the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the clinicId parameter from the query parameters
  const ClinicIdSearch = queryParams.get("clinicId");
  const dispatch = useDispatch();

  let Title =
    languageValue === "En"
      ? "Trouvez un vétérinaire en un clic"
      : "Trouvez un vétérinaire en un clic";
  let Subtitle =
    languageValue === "En"
      ? "Prendre rendez-vous en ligne"
      : "Prendre rendez-vous en ligne";

  // get place from local storage
  useEffect(() => {
    if (localStorage.getItem("searchCodePostal")) {
      setPlace(localStorage.getItem("searchCodePostal"));
    }
  }, [localStorage.getItem("searchCodePostal")]);

  const handleSubmit = () => {
    AXIOS_GET(
      GET_SEARCH_CLINICS_NEAREST_URL +
        `?latitude=${0}&longitude=${0}&species=${""}&searchText=${""}`
    )
      .then((res) => {
        dispatch(setSearchedClinics(res.data));
      })
      .catch((err) => {});
  };

  // check if searchData is empty or null and submit the form

  useEffect(() => {
    if (
      searchData === null ||
      searchData === undefined ||
      searchData.length === 0
    ) {
      handleSubmit();
    }
  }, [searchData]);

  return (
    <div className="change-container">
      <HomeContainer Title={Title} Subtitle={Subtitle} display_map="false">
        <div className="search-container" ref={bookPage}>
          <div className="intro">
            {/* <h3>Vétérinaires à <span>{place?place:"Genève"}</span></h3> */}
            {/* <p>Rendez-vous vétérinaire à {place?place:"Genève"} avec réservation en ligne</p> */}
            <h3>Prenez rendez-vous en ligne avec un vétérinaire </h3>
          </div>
          <SearchCard ClinicIdSearch={ClinicIdSearch} />
        </div>
      </HomeContainer>
    </div>
  );
};
