import "../../../styles/client/account/Reset.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { AXIOS_POST } from "../../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { POST_RESET_URL } from "../../../helper/Url";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useState } from "react";
import Loader from "../../../helper/Loader/Loader";
import Translate from "../../../config/Translate";
import FormErrors from "../../../helper/Message/FormErrors";

function Reset() {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmited] = useState(false);
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const languageValue = useSelector(selectLanguage);
  let requestError =
    languageValue === "En"
      ? "Something went wrong"
      : "Quelque chose s’est mal passé";
  let requestSuccess =
    languageValue === "En"
      ? "Password reset was successful"
      : "La réinitialisation du mot de passe a réussi";

  const onSubmit = (data) => {
    setIsSubmited(true);
    AXIOS_POST(POST_RESET_URL, data,token)
      .then((res) => {
        toast.success(requestSuccess, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setIsSubmited(false);
        setTimeout(() => navigate("/login", { replace: true }), 3500);
      })
      .catch((error) => {
        setIsSubmited(false);
        toast.error(requestError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      });
  };

  return (
    <div className="reset-container">
      <ToastContainer />
      <div className="reset-wrapper">
        <h1 className="black font-16">Connexion à <span className="main-color">Oneclic.vet</span></h1>
        <h1 className="main-color lg-size">Entrez votre nouveau mot de passe</h1>
        <span className="black reset-dialog text-center">
        Entrez ci-dessous votre nouveau mot de passe et confirmez-le. Votre mot de passe doit contenir au moins 8 caractères avec une majuscule, une minuscule et un chiffre.
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="password"
            placeholder="Mot de passe"
            {...register("password", { required: true })}
          />
          {errors.password && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          <input
            type="password"
            placeholder="Confirmer le mot de passe"
            {...register("confirmPassword", {
              required: true,
              validate: (val) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
          />
          {errors.confirmPassword?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.register.cpassword.error"} />
            </FormErrors>
          )}
          {isSubmitted ? (
            <button type="button">
              <Loader color="white" />
            </button>
          ) : (
            <button>
              VALIDER
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Reset;
