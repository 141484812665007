import React, { useEffect } from "react";
import "../../../styles/client/customer/Dashboard.scss";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import AccountInfoIcon from "../../../assets/Icons/info_account.png";
import DashboardIcon from "../../../assets/Icons/dashicon.png";
import CalendarIcon from "../../../assets/Icons/calendar_icon.png";
import PersonIcon from "../../../assets/Icons/person_white.png";
import PetIcon from "../../../assets/Icons/pet_white.png";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { GET_SINGLE_CLINIC_URL } from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";

const ClinicDashboard = ({ changeBannerInfo }) => {
  const id = localStorage.getItem("user_id");
  const token = localStorage.getItem("user_token");
  const languageValue = useSelector(selectLanguage);
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  // Split the pathname into parts based on '/'
  const pathParts = pathname.split('/');

  // Get the last part of the pathname
  const lastPart = pathParts[pathParts.length - 1];


  const getClicInfo = () => {
    AXIOS_GET(GET_SINGLE_CLINIC_URL + `${id}`, token)
      .then((res) => {
        if (!res.data?.firstname || !res.data?.lastname) {
          navigate("/companypreinfo");
        }

      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getClicInfo();
  }
    , []);


  return (
    <div className="clinic-dashboard-container">
      <Sidebar changeBannerInfo={changeBannerInfo} />
      <div style={{ flex: 1 }}>
        <nav className="mobo_nav_container bg_purple_strong">
          <ul>
            <li  className={lastPart === 'summary' ? 'active' : ''}>
              <Link to={"/clinic/dashboard/summary"}>
                <img src={DashboardIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === 'account'? 'active':''}>
              <Link to={"/clinic/dashboard/account"}>
                <img src={PersonIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === 'myclinicinfo'? 'active':''}>
              <Link to={"/clinic/dashboard/myclinicinfo"}>
                <img src={AccountInfoIcon} alt="" srcset="" />
              </Link>
            </li>
            {/* <li>
              <Link to={"/clinic/dashboard/animals/1"}>
                <img src={PetIcon} alt="" srcset="" />
              </Link>
            </li> */}
            <li className={lastPart === 'rendezvous'? 'active':''}>
              <Link to={"/clinic/dashboard/rendezvous"}>
                <img src={CalendarIcon} alt="" srcset="" />
              </Link>
            </li>
          </ul>
        </nav>
        <Outlet />
      </div>
    </div>
  );
};

export default ClinicDashboard;
