import React from "react";
import "../../../../../../../styles/client/clinic/GridTimetable.scss";
import Workday from "./Workday";
import {
  AXIOS_GET,
  AXIOS_PATCH,
  AXIOS_POST,
} from "../../../../../../../config/axios";
import ErrorToast from "../../../../../../../helper/Message/ErrorToast";
import {
  GET_PROVIDER_TIMETABLE_URL,
  POST_CLINICS_TIMETABLE_URL,
  POST_PROVIDERS_TIMETABLE_INITIALIZE_URL,
  UPDATE_CLINIC_TIMETABLE_DISABLE_DAY_URL,
  UPDATE_CLINIC_TIMETABLE_ENABLE_DAY_URL,
  UPDATE_PROVIDER_TIMETABLE_DISABLE_DAY_URL,
  UPDATE_PROVIDER_TIMETABLE_ENABLE_DAY_URL,
} from "../../../../../../../helper/Url";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import Loader from "../../../../../../../helper/Loader/Loader";

const GridTimeTableComponent = ({ header = false, starttime, providerId }) => {
  const id = localStorage.getItem("user_id");
  const token = localStorage.getItem("user_token");
  const [timeTableData, settimeTableData] = useState([]);
  const [loadingData, setloadingData] = useState(false);

  // Get time table if not exist initialize it
  const gettimetable = () => {
    setloadingData(true);
    AXIOS_GET(GET_PROVIDER_TIMETABLE_URL + `${providerId}`, token)
      .then((res) => {
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          settimeTableData(
            res.data?.sort((a, b) => a?.dayOfWeek - b?.dayOfWeek)
          );
          setloadingData(false);
        } else if (Array.isArray(res?.data) && res?.data?.length == 0) {
          initializetimetable();
        }
      })
      .catch((error) => {
        setloadingData(false);
        ErrorToast(error);
      });
  };

  // Initialize time table
  const initializetimetable = () => {
    const data = {
      days: [1, 2, 3, 4, 5, 6,7],
      slotInterval: 0,
      startTime: {
        hours: 7,
        minutes: 0,
      },
      endTime: {
        hours: 24,
        minutes: 0,
      },
    };
    AXIOS_POST(
      POST_PROVIDERS_TIMETABLE_INITIALIZE_URL + `${providerId}`,
      data,
      token
    )
      .then((res) => {
        gettimetable();
      })
      .catch((error) => {});
  };

  // Disable specific day

  const disableday = (id) => {
    AXIOS_PATCH(
      UPDATE_PROVIDER_TIMETABLE_DISABLE_DAY_URL + `${id}/${providerId}`,
      "",
      token
    )
      .then((res) => {})
      .catch((error) => {
        ErrorToast(error);
      });
  };

  // Enable specific day
  const enableday = (id) => {
    AXIOS_PATCH(
      UPDATE_PROVIDER_TIMETABLE_ENABLE_DAY_URL + `${id}/${providerId}`,
      "",
      token
    )
      .then((res) => {})
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const formatTime = (hour) => {
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinutes = "00";
    return `${formattedHour}:${formattedMinutes}`;
  };

  const TimeDisplay = (hour) => {
    return formatTime(hour);
  };

  useEffect(() => {
    gettimetable();
  }, [providerId]);

  return (
    <div className="grid-timetable-container">
      <ToastContainer />
      <div className="table-contents">
        {!loadingData ? (
          <>
            <div className="column">
              {header ? <div className="day"></div> : ""}

              {timeTableData[0]?.slots.map((slotinfo) => (
                <div className="slot">
                  <button className="hours">
                    {TimeDisplay(slotinfo?.start?.hours)}
                  </button>
                </div>
              ))}
            </div>

            {timeTableData.map((daydetail) => (
              <Workday
                header={header}
                providerId={providerId}
                weekday={daydetail.dayOfWeek}
                dayoff={daydetail.status}
                daydetail={daydetail}
                disableday={disableday}
                enableday={enableday}
              />
            ))}
          </>
        ) : (
          <div className="center">
            <div>
              <Loader size={23} color="#8B7BCB"/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default GridTimeTableComponent;
