import Map from "../../../components/client/common/Map";
import Header from "../../../components/client/common/Header";
import Footer from "../../../components/client/common/Footer";
import BannerHome from "../common/BannerHome";

const HomeContainer = ({ Title, Subtitle,display_map=true, children}) => {
  return (
    <>
      <Header />
      <BannerHome Title={Title} Subtitle={Subtitle} />
      {children}
      {/* {display_map == true? <Map/>:''} */}
      <Footer />
    </>
  );
};

export default HomeContainer;
