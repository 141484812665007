import { useSelector } from "react-redux";
import VetVerify from "../../../components/client/account/VetVerify";
import ClientContainer from "../../../components/client/container/Container";
import { selectLanguage } from "../../../features/LanguageSlice";

const VerifyPage = () => {
  const languageValue = useSelector(selectLanguage);

  let Title = languageValue === "En" ? "Inscription vétérinaire" : "Inscription vétérinaire";
  let Subtitle =
    languageValue === "En"
      ? "Votre rendez-vous vétérinaire en un clic"
      : "Votre rendez-vous vétérinaire en un clic";

  return (
    <div className="verify-container">
      <ClientContainer Title={Title} Subtitle={Subtitle}>
        <VetVerify />
      </ClientContainer>
    </div>
  );
};

export default VerifyPage;
