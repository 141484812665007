import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { set } from "nprogress";

function PlaceAutocomplete({
  placeholder = "Entrez un emplacement",
  updateLocation,
  value,
  onChange,
  setCordinate,
}) {
  const [place, setPlace] = useState(null);

  useEffect(() => {
    if (place) {
      getGeolocation();
    }
  }, [place]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      setPlace(selectedPlace);
    });
  };

  const getGeolocation = () => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      if (updateLocation) updateLocation(lat(), lng(), place.formatted_address);
      if (setCordinate)
        setCordinate({
          lat: lat(),
          lng: lng(),
          place: place.formatted_address,
        });
      return `Latitude: ${lat()}, Longitude: ${lng()}`;
    }
    return "no data";
  };

  useEffect(() => {
    getGeolocation();
  }, [setCordinate]);

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={getGeolocation}
      options={{ componentRestrictions: { country: "ch" } }}
    >
      <input
        type="text"
        autoComplete="off"
        onChange={onChange}
        className="border_purple"
        // placeholder={value ? value : placeholder}
        placeholder={placeholder}
        onFocus={(e) => (e.target.value = "")}
      />
    </Autocomplete>
  );
}

export default PlaceAutocomplete;
