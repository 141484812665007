import Fade from "react-reveal/Fade";
const FormErrors = ({ children }) => {
  return (
    <div className="error_message">
      <Fade bottom>{children}</Fade>
    </div>
  );
};

export default FormErrors;
