import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import '../../../styles/search/General.scss'
import SearchContainer from "../../../components/client/container/SearchContainer";
import { ConfirmedCard } from "./ConfirmedCard";


export const  Confirmed = ()=>{
  const languageValue = useSelector(selectLanguage);
  let Title =
    languageValue === "En"
      ? "Confirm booking"
      : "Confirmez votre rendez-vous";
//   let Subtitle =
//     languageValue === "En"
//       ? "Make an appointment online"
//       : "Prendre rendez-vous en ligne";

  return (
    <div className="change-container">
      <SearchContainer Title={Title}  display_map="false">
       <ConfirmedCard/>
      </SearchContainer>
    </div>
  );
}

