import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ErrorToast = (error) => {
  let errorMessage = '';
  // check if error is a string

  if (typeof error === 'string') {
    toast.error(error, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'colored',
    });
    return;
  }

  if (error) {
    if (error?.response?.data.message) {
      errorMessage = error.response.data.message;
    }

    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((error) =>
        toast.error(error, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: 'colored',
        })
      );
    } else {
      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: 'colored',
      });
    }
  }
};

export default ErrorToast;
