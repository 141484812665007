import React, { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Translate from "../../../config/Translate";
import { useRef } from "react";
import { AXIOS_POST, AXIOS_PUT } from "../../../config/axios";
import { POST_CHANGE_URL, PUT_CREDENTIALS_URL } from "../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import SuccessToast from "../../../helper/Message/SuccessToast";
import Loader from "../../../helper/Loader/Loader";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormErrors from "../../../helper/Message/FormErrors";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const Form1 = () => {
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [emailLoading, setEmailLoading] = useState(false);
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const changeEmail = (data) => {
    setEmailLoading(true);
    AXIOS_PUT(PUT_CREDENTIALS_URL, data, token)
      .then(() => {
        setEmailLoading(false);
        SuccessToast("Votre information a été modifiée avec succès");
        localStorage.setItem("user_email", data.email);
        // setTimeout(() => {
        //   navigate("/verify");
        // }, 300);
      }) 

      .catch((Error) => {
        setEmailLoading(false);
        ErrorToast(Error);
      });
  };
  return (
    <form onSubmit={handleSubmit(changeEmail)}>
      <div className="flex-container gap-10" style={{ alignItems: "baseline" }}>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Nouvel e-mail*</label>
          <input
            className="margin-bottom-26 border_purple"
            type="email"
            {...register("email", { required: true })}
            placeholder="Votre nouvel e-mail*"
          />
          {errors.email?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.email.error"} />
            </FormErrors>
          )}
        </div>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Confirmez votre e-mail*</label>
          <input
            className="margin-bottom-26 border_purple"
            type="email"
            {...register("confirmEmail", {
              required: true,
              validate: (val) => {
                if (watch("email") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
            placeholder="Confirmez votre e-mail*"
          />
          {errors.confirmEmail?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.email.error"} />
            </FormErrors>
          )}
          {errors.confirmEmail?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.confirm.email.error"} />
            </FormErrors>
          )}
        </div>
      </div>
      <div className="input-container btn-container">
        {emailLoading ? (
          <button type="button" className="bg_purple_strong">
            <Loader color="white"></Loader>{" "}
          </button>
        ) : (
          <button className="bg_purple_strong">Valider les modifications</button>
        )}
      </div>
    </form>
  );
};

const Form2 = () => {
  const token = localStorage.getItem("user_token");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [cpasswordvisible, setcpasswordvisible] = useState(false); 
  const [passwordvisible, setpasswordvisible] = useState(false); 
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const changePassword = (data) => {
    setPasswordLoading(true);
    AXIOS_PUT(PUT_CREDENTIALS_URL, data, token)
      .then(() => {
        setPasswordLoading(false);
        SuccessToast("Votre mot de passe a été modifié avec succès");
      })

      .catch((Error) => {
        setPasswordLoading(false);
        ErrorToast(Error);
      });
  };

  return (
    <form className="" onSubmit={handleSubmit(changePassword)}>
      <div className="flex-container gap-10" style={{ alignItems: "baseline" }}>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Nouveau mot de passe*</label>
          <div className="input-icon margin-bottom-26 flex-container border_purple" style={{ flexDirection:"row" }}>
          <input
            type={passwordvisible ? "text" : "password"}
            {...register("password", {
              required: true,
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
            })}
            placeholder="Votre nouveau mot de passe*"
          />
                  {passwordvisible ? <VisibilityOutlined onClick={()=>setpasswordvisible(!passwordvisible)} className="svg_purple2"/>:<VisibilityOffOutlined className="svg_purple2" onClick={()=>setpasswordvisible(!passwordvisible)}/>}

          </div>   
          {errors.password?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.password?.type === "pattern" && (
            <FormErrors>
              <Translate Word={"page.register.passwordPatern.error"} />
            </FormErrors>
          )}
        </div>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Confirmez mot de passe*</label>
          <div className="input-icon margin-bottom-26 flex-container border_purple" style={{ flexDirection:"row" }}>
                <input
                  className=""
                  type={cpasswordvisible ? "text" : "password"}
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  placeholder="Confirmez votre mot de passe*"
                />
                
                  {cpasswordvisible ? <VisibilityOutlined onClick={()=>setcpasswordvisible(!cpasswordvisible)} className="svg_purple2"/>:<VisibilityOffOutlined className="svg_purple2" onClick={()=>setcpasswordvisible(!cpasswordvisible)}/>}
                
            </div>
          {errors.confirmPassword?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.confirmPassword?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.register.cpmatch.error"} />
            </FormErrors>
          )}
        </div>
      </div>
      <div className="btn-container">
        {passwordLoading ? (
          <button type="button" className="bg_purple_strong">
            <Loader color="white"></Loader>{" "}
          </button>
        ) : (
          <button className="bg_purple_strong">Valider les modifications</button>
        )}
      </div>
    </form>
  );
};

const Form3 = () => {
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [emailLoading, setEmailLoading] = useState(false);
  const [cpasswordvisible, setcpasswordvisible] = useState(false); 
  const [passwordvisible, setpasswordvisible] = useState(false); 
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const changeInfo = (data) => {
    setEmailLoading(true);
    AXIOS_PUT(PUT_CREDENTIALS_URL, data, token)
      .then(() => {
        setEmailLoading(false);
        SuccessToast("Votre information a été modifiée avec succès");
        localStorage.setItem("user_email", data.email);
        setTimeout(() => {
          navigate("/verify");
        }, 500);
      })

      .catch((Error) => {
        setEmailLoading(false);
        ErrorToast(Error);
      });
  };
  return (
    <form onSubmit={handleSubmit(changeInfo)}>
      <div className="flex-container gap-10" style={{ alignItems: "baseline" }}>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Nouvel e-mail*</label>
          <input
            className="margin-bottom-26 border_purple"
            type="email"
            {...register("email", { required: true })}
            placeholder="Votre nouvel e-mail*"
          />
          {errors.email?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.email.error"} />
            </FormErrors>
          )}
        </div>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Confirmez votre e-mail*</label>
          <input
            className="margin-bottom-26 border_purple"
            type="email"
            {...register("confirmEmail", {
              required: true,
              validate: (val) => {
                if (watch("email") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
            placeholder="Confirmez votre e-mail*"
          />
          {errors.confirmEmail?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.email.error"} />
            </FormErrors>
          )}
          {errors.confirmEmail?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.confirm.email.error"} />
            </FormErrors>
          )}
        </div>
      </div>
      <div className="flex-container gap-10" style={{ alignItems: "baseline" }}>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Nouveau mot de passe*</label>
          <div className="input-icon margin-bottom-26 flex-container border_purple">
          <input
            type={passwordvisible ? "text" : "password"}
            {...register("password", {
              required: true,
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
            })}
            placeholder="Votre nouveau mot de passe*"
          />
            {passwordvisible ? <VisibilityOutlined onClick={()=>setpasswordvisible(!passwordvisible)} className="svg_purple2"/>:<VisibilityOffOutlined className="svg_purple2" onClick={()=>setpasswordvisible(!passwordvisible)}/>}
          </div>
          {errors.password?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.password?.type === "pattern" && (
            <FormErrors>
              <Translate Word={"page.register.passwordPatern.error"} />
            </FormErrors>
          )}
        </div>
        <div className="input-container margin-bottom-0">
          <label htmlFor="" className="text_purple">Confirmez mot de passe*</label>
          <div className="input-icon margin-bottom-26 flex-container border_purple">
                <input
                  className=""
                  type={cpasswordvisible ? "text" : "password"}
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  placeholder="Confirmez votre mot de passe*"
                />
                  {cpasswordvisible ? <VisibilityOutlined onClick={()=>setcpasswordvisible(!cpasswordvisible)} className="svg_purple2"/>:<VisibilityOffOutlined className="svg_purple2" onClick={()=>setcpasswordvisible(!cpasswordvisible)}/>}
            </div>
          {errors.confirmPassword?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.confirmPassword?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.register.cpmatch.error"} />
            </FormErrors>
          )}
        </div>
      </div>
      <div className="input-container btn-container">
        {emailLoading ? (
          <button type="button" className="bg_purple_strong">
            <Loader color="white"></Loader>{" "}
          </button>
        ) : (
          <button className="bg_purple_strong">Valider les modifications</button>
        )}
      </div>
    </form>
  );
};


const Account = () => {
  const cusEmain = localStorage.getItem("user_email");
  const userFirstname = localStorage.getItem("user_firstname");
  const languageValue = useSelector(selectLanguage);
  const authType = localStorage.getItem("authType");

  return (
    <div className="content-container">
      <ToastContainer />
      <h1 className="welcome-title">
        <Translate Word={"page.Bienvenue"} />
      </h1>
      <h1 className="page-name text_purple">
        Votre compte <span className="black">en un clic</span>
      </h1>

      <h1 className="page-name sm-page-name text_purple">
        <Translate Word={"page.myemail"} />
      </h1>
      <div className="my-oldemail">
        <MailOutlineIcon className="svg_purple2"></MailOutlineIcon>
        <span>{cusEmain}</span>
      </div>

      {authType == "normal" ? <>
      <h1 className="page-name sm-page-name svg_purple2">
        <Translate Word={"page.changemyemail"} />
      </h1>
      <div className="change-form">
        <Form1 />
      </div>

      <h1 className="page-name sm-page-name text_purple">
        <Translate Word={"page.changemypassword"} />
      </h1>
      <div className="change-form">
        <Form2 />
      </div>
      </>:
      <>
      <h1 className="page-name sm-page-name text_purple">
        Modifier les informations de connexion
      </h1>
      <div className="change-form">
        <Form3 />
      </div>
      </>
      }

    </div>
  );
};

export default Account;
