import React, { useState } from "react";
import SettingTimeslot from "./SettingTimeslot";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { GET_SPECIAL_DAYS_URL } from "../../../../../../helper/Url";
import { AXIOS_GET } from "../../../../../../config/axios";
import { get } from "react-hook-form";

const SelectTime = ({ header = null, entId = null }) => {
  const [timeIncrement, settimeIncrement] = useState([]);
  const [datas, setdata] = useState([]);
  const token = localStorage.getItem("user_token");

  // get all special days

  const getSpecialDays = () => {
    const data = {};
    data.id = entId;
    AXIOS_GET(GET_SPECIAL_DAYS_URL + `/${data.id}`, token)
      .then((res) => {
        setdata(res.data);
      })
      .catch((err) => {
        console.log("err is", err);
      });
  };

  useEffect(() => {
    getSpecialDays();
  }, []);

  const handleLoadData = () => {
    getSpecialDays();
  };

  const handleDeleteIndex = (index) => {
    let temp = [...timeIncrement];
    temp.splice(index, 1);
    settimeIncrement(temp);
  };

  return (
    <>
      {(timeIncrement.length > 0 || datas.length > 0) && header && (
        <>
          <div className="input_detail gap120  mg-bm-10">
            <div className="date_section">
              <div className="in main-color text_purple">Date</div>
            </div>
            <div className="time_section">
              <div className="time_list ">
                <div className="input_detail align-start">
                  <div className="reason"></div>
                  <div className="in_time_space time-box">
                    <div className="in_time_space main-color text_purple">
                      Heure de début
                    </div>
                  </div>
                  <div className="time-box">
                    <div className="out main-color text_purple">
                      Heure de fin
                    </div>
                  </div>
                  <div className="action"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {datas.map((time, index) => (
        <SettingTimeslot
          key={index}
          entId={entId}
          cnumber={index + "random"}
          LoadData={handleLoadData}
          cid={time._id}
          ctimeRanges={time.timeRanges}
          cdate={new Date(time.date).toISOString().slice(0, 10)}
        />
      ))}
      {timeIncrement.map((time, index) => (
        <SettingTimeslot
          key={index}
          entId={entId}
          cnumber={index}
          LoadData={handleLoadData}
          deleteIndex={() => handleDeleteIndex(index)}
        />
      ))}
      <div
        className="add-date"
        onClick={() => settimeIncrement([...timeIncrement, 1])}
      >
        <AddIcon className="admin_svg" /> Ajouter une date
      </div>
    </>
  );
};

export default SelectTime;
