import "../../../styles/client/common/Header.scss";
import logo from "../../../assets/Logos/logo-white.png";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import Menucomp from "./Menu";
import { useEffect, useState } from "react";
import Translate from "../../../config/Translate";
import LanguageSwitcher from "../../common/LanguageSwitcher";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Person } from "@mui/icons-material";
import { ValidateValue } from "../../../helper/Message/ValidateValue";
import { selectUserInfo } from "../../../features/UserInfoSlice";
import { useSelector } from "react-redux";

function Header() {
  const navigate = useNavigate();
  const [showMenu, setShowmenu] = useState(false);
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const selectUserinfoData = useSelector(selectUserInfo);

  const hidemenu = () => {
    setShowmenu(false);
  };
  const RedirectToDashboard = () => {
    const getuserRole = localStorage.getItem("user_accountType");
    if (getuserRole == 1) {
      navigate("/clinic/dashboard/summary");
    }
    if (getuserRole == 0) {
      navigate("/customer/dashboard/summary");
    }
  };

  const loggedIn = () => {
    const user = localStorage.getItem("user_token");

    if (user != undefined && user != null) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 2) {
        document.querySelector(".header-container").classList.add("scrolled");
      } else {
        document
          .querySelector(".header-container")
          .classList.remove("scrolled");
      }
    });
    return () => window.removeEventListener("scroll", () => {});
  }, []);

  // change get user firstname and lastname whenever localstorage change

  useEffect(() => {
    if (localStorage.getItem("user_firstname")) {
      setUserFirstname(localStorage.getItem("user_firstname"));
    }
    if (localStorage.getItem("user_lastname")) {
      setUserLastname(localStorage.getItem("user_lastname"));
    }
  }, [selectUserinfoData]);

  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" onClick={() => navigate("/")} />
      </div>
      <div className="action-container">
        <div
          className="action-btn btn-hide"
          onClick={() => navigate("/search")}
        >
          Rechercher un vétérinaire
        </div>
        {loggedIn() === false ? (
          <>
            <div
              className="action-btn btn-hide"
              onClick={() => navigate("/clinic")}
            >
              <Translate Word="header.ifveterian" />
            </div>
            <div
              className="action-btn btn-hide"
              onClick={() => navigate("/login")}
            >
              <Person className="svg-right-space" />
              <Translate Word={"header.login"} />
            </div>
            <div
              className="action-btn  btn-hide"
              onClick={() => navigate("/insicription")}
            >
              <Translate Word={"header.Register"} />
            </div>
          </>
        ) : (
          <div className="action-btn  btn-hide" style={{ marginRight: "70px" }}>
            <Person onClick={RedirectToDashboard} />{" "}
            <span onClick={RedirectToDashboard}>
              {" "}
              {userFirstname != "undefined" &&
              userFirstname != null &&
              userFirstname
                ? userFirstname
                : ""}{" "}
              {userLastname != "undefined" &&
              userLastname != null &&
              userLastname
                ? userLastname
                : ""}
            </span>
          </div>
        )}

        {/* <div className="action-btn  btn-hide">
          <LanguageSwitcher />
        </div> */}
        <div className="action-btn btn-hide">
          <div className="flex-container brands-container">
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://www.instagram.com/oneclic.vet39/">
                <i class="fa-brands fa-instagram"></i>
              </Link>
            </div>
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://www.facebook.com/oneclic.vet">
                <i class="fa-brands fa-facebook-f"></i>
              </Link>
            </div>
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://www.linkedin.com/company/oneclic-vet/">
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
            </div>
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://www.youtube.com/channel/UC0O2a6--nHRwtez4misTMsA">
                <i class="fa-brands fa-youtube"></i>
              </Link>
            </div>
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://www.tiktok.com/@oneclic.vet?lang=fr">
                <i class="fa-brands fa-tiktok"></i>
              </Link>
            </div>
            <div className="flex-column brands-icon">
              <Link target="_blank" to="https://x.com/Oneclicvet">
              <i class="fa-brands fa-x-twitter"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="action-btn">
          <MenuIcon
            className="humberger-menu"
            onClick={() => setShowmenu(true)}
          />
        </div>
      </div>
      {showMenu && <Menucomp setShowmenu={hidemenu} />}
    </div>
  );
}

export default Header;
