import React from 'react'
import TimeTable from './Components/gridTimetable/Timetable'

const GridTimeTable = ({providerId}) => {

  return (
    <>
     <TimeTable header={true} starttime={'00:00'} providerId={providerId}/>
    </>
  )
}

export default GridTimeTable
