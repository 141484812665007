import "../../../styles/client/account/Register.scss";
import CustomerContainer from "../../../components/client/container/CustomerContainer";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import CustomerDashboard from "../../../components/client/customer/CustomerDashboard";
import { useEffect, useState } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SINGLE_CLIENTS_URL } from "../../../helper/Url";
import { useNavigate } from "react-router-dom";
import Loader from "../../../helper/Loader/Loader";

function CustomerDashboardPage() {
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const id = localStorage.getItem("user_id");
  const languageValue = useSelector(selectLanguage);
  const [infoAvailable, setInfoAvailable] = useState(false);
  const [title, setTitle] = useState(
    languageValue === "En" ? "Mon espace" : "Mon espace"
  );
  const [subtitle, setSubtitle] = useState(
    languageValue === "En"
      ? "Votre compte en un clic"
      : "Votre compte en un clic"
  );
  let DefaultTitle = languageValue === "En" ? "Mon espace" : "Mon espace";
  let DefaultSubtitle =
    languageValue === "En"
      ? "Votre compte en un clic"
      : "Votre compte en un clic";

  const changeBannerInfo = (
    titlep = DefaultTitle,
    subtitlep = DefaultSubtitle
  ) => {
    setTitle(titlep);
    setSubtitle(subtitlep);
  };

 


  const getCustomerInfo = () => {
    AXIOS_GET(GET_SINGLE_CLIENTS_URL + `${id}`, token)
      .then((res) => {
       if(!res.data?.firstname || !res.data?.lastname){
        navigate("/clientpreinfo");
       }else{
        setInfoAvailable(true);
       }
      
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <div className="account-page-container">
      <CustomerContainer
        Title={title}
        Subtitle={subtitle}
        ShowMap={false}
        ShowFooter={true}
      >
        {
          infoAvailable ? <CustomerDashboard changeBannerInfo={changeBannerInfo} /> : 
          <div className="check_basic_info">
            <Loader />
          </div>
        }
       

      </CustomerContainer>
    </div>
  );
}

export default CustomerDashboardPage;
