import "../../../styles/client/common/BannerHome.scss";
import { motion, AnimatePresence } from "framer-motion";
import Fade from "react-reveal/Fade";
import PersonIcon from "../../../assets/Icons/person.svg";
import PersonIconW from "../../../assets/Icons/personW.svg";
import PawIcon from "../../../assets/Icons/paw.svg";
import PawIconW from "../../../assets/Icons/pawW.svg";
import MapIcon from "../../../assets/Icons/map_pointer.svg";
import MapIconW from "../../../assets/Icons/map_pointerW.svg";
import SearchIcon from "../../../assets/Icons/search.svg";
import SearchIconBlue from "../../../assets/Icons/searchBlue.svg";
import Autocomplete from "./Autocomplete";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Translate from "../../../config/Translate";
import { useEffect, useState } from "react";
import { AXIOS_GET, AXIOS_POST } from "../../../config/axios";
import ErrorToast from "../../../helper/Message/ErrorToast";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { GET_SEARCH_CLINICS_NEAREST_URL } from "../../../helper/Url";
import { setSearchedClinics } from "../../../features/SearchSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../helper/Loader/Loader";

const BannerHome = ({ Title = "No title", Subtitle = "No subTitle" }) => {
  const navigate = useNavigate();
  const [cordinate, setcordinate] = useState({ lat: 0, lng: 0 });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [refreshl, setRefreshl] = useState(true);

  const [nomDuVet, setNomDuVet] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [typeAnimal, setTypeAnimal] = useState("");

  const setVetname = (e) => {
    setNomDuVet(e.target.value);
    localStorage.setItem("searchVet", e.target.value);
  };

  const setTypeAnimalHandler = (e) => {
    setTypeAnimal(e.target.value);
    localStorage.setItem("searchSpecies", e.target.value);
  };

  // if cordination is not null then set the local storage of codePostal

  useEffect(() => {
    if (cordinate.place) {
      localStorage.setItem("searchCodePostal", cordinate.place);
    }
  }, [cordinate]);

  // if the last part off url is not search then clear the local storage of nomDuVet and typeAnimal

  useEffect(() => {
    if (window.location.href.split("/").pop() !== "search") {
      localStorage.removeItem("searchVet");
      localStorage.removeItem("searchSpecies");
      localStorage.removeItem("searchCodePostal");
    } else {
      setNomDuVet(localStorage.getItem("searchVet"));
      setTypeAnimal(localStorage.getItem("searchSpecies"));
      setCodePostal(localStorage.getItem("searchCodePostal"));
    }
  }, []);

  if (refreshl) {
    setRefreshl(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AXIOS_GET(
      GET_SEARCH_CLINICS_NEAREST_URL +
        `?latitude=${cordinate.lat}&longitude=${cordinate.lng}&species=${typeAnimal}&searchText=${nomDuVet}`
    )
      .then((res) => {
        setLoading(false);
        dispatch(setSearchedClinics(res.data));
        setTimeout(() => {
          navigate("/search");
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const setCord = (cordinate) => {
    setcordinate(cordinate);
  };

  // on enter submit the form

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    });
    return () => {
      window.removeEventListener("keypress", (e) => {
        if (e.key === "Enter") {
          handleSubmit(e);
        }
      });
    };
  }, []);

  return (
    <>
      <AnimatePresence>
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <motion.div
          className="banner-home-container"
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0 }}
        >
          <Fade bottom>
            <h1>{Title}</h1>
          </Fade>
          <Fade bottom>
            <span>{Subtitle}</span>
          </Fade>
          <div className="form_search">
            <form onSubmit={handleSubmit}>
              <div className="input_container">
                <label htmlFor="personal">
                  <img src={PersonIcon} alt="" />
                </label>
                <input
                  type="text"
                  placeholder="NOM DU VÉTÉRINAIRE OU DE L’ÉTABLISSEMENT"
                  id="personal"
                  value={nomDuVet}
                  onChange={setVetname}
                  autocomplete="off"
                  onFocus={(e) => (e.target.value = "")}
                />
              </div>
              <div className="input_container">
                <label htmlFor="personal">
                  <img src={MapIcon} alt="" />
                </label>
                {/* <input
                type="text"
                placeholder="place"
                id="personal"
                defaultValue={"code postal ou ville"}
              /> */}
                {/* <Autocomplete placeholder={codePostal?codePostal:  "CODE POSTAL OU VILLE"} setCordinate={setCord} /> */}
                <Autocomplete
                  placeholder={"CODE POSTAL OU VILLE"}
                  setCordinate={setCord}
                />
              </div>
              <div className="input_container">
                <label htmlFor="personal">
                  <img src={PawIcon} alt="" />
                </label>
                <select
                  value={typeAnimal}
                  onChange={setTypeAnimalHandler}
                  name=""
                  id=""
                  onFocus={(e) => (e.target.value = "")}
                >
                  <option value="" selected>
                    TYPE D’ANIMAL
                  </option>
                  <option value="Chat">Chat</option>
                  <option value="Chien">Chien</option>
                  <option value="NAC">NAC</option>
                </select>
              </div>
              <div
                className="input_container submitButton"
                onClick={handleSubmit}
              >
                {loading ? (
                  <Loader color="white" />
                ) : (
                  <>
                    <button type="button">
                      <Translate Word="page.search" />
                    </button>
                    <img src={SearchIcon} alt="" />
                  </>
                 )}
              </div>
            </form>
          </div>
        </motion.div>
        <div className="form_search_mobo">
          <form onSubmit={handleSubmit}>
            <div className="input_container">
              <label htmlFor="personal">
                <img src={PersonIconW} alt="" />
              </label>
              <input
                type="text"
                placeholder="NOM DU VÉTÉRINAIRE OU DE L’ÉTABLISSEMENT"
                id="personal"
                onChange={(e) =>
                  localStorage.setItem("searchVet", e.target.value)
                }
                // defaultValue={"Nom du vétérinaire ou de l’établissement"}
              />
            </div>
            <div className="input_container">
              <label htmlFor="personal">
                <img src={MapIconW} alt="" />
              </label>
              {/* <input
                type="text"
                placeholder="place"
                id="personal"
                defaultValue={"code postal ou ville"}
              /> */}
              <Autocomplete
                placeholder={"CODE POSTAL OU VILLE"}
                setCordinate={setCord}
              />
            </div>
            <div className="input_container">
              <label htmlFor="personal">
                <img src={PawIconW} alt="" />
              </label>
              <select
                value={localStorage.getItem("searchSpecies")}
                onChange={(e) =>
                  localStorage.setItem("searchSpecies", e.target.value)
                }
                name=""
                id=""
              >
                <option value="" selected>
                  TYPE D’ANIMAL
                </option>
                <option value="Chat">Chat</option>
                <option value="Chien">Chien</option>
                <option value="NAC">NAC</option>
              </select>
            </div>
            <div
              className="input_container submitButton"
              type="submit"
              onClick={handleSubmit}
            >
              {loading ? (
                <Loader color="#2CB5B2" />
              ) : (
                <>
                  <button type="button">
                    <Translate Word="page.search" />
                  </button>
                  <img src={SearchIconBlue} alt="" />
                </>
               )}
            </div>
          </form>
        </div>
      </AnimatePresence>
    </>
  );
};

export default BannerHome;
