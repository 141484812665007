import Banner from "../../../components/client/common/CustomerBanner";
import Map from "../../../components/client/common/Map";
import Header from "../../../components/client/common/Header";
import Footer from "../../../components/client/common/Footer";

const CustomerContainer = ({
  Title,
  Subtitle,
  ShowMap = "true",
  ShowFooter = "true",
  children,
}) => {
  return (
    <>
      <Header />
      <Banner Title={Title} Subtitle={Subtitle} />
      {children}
      {ShowMap && <Map />}
      {ShowFooter && <Footer />}
    </>
  );
};

export default CustomerContainer;
