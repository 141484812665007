  import React, { useEffect } from "react";
import "../../../styles/client/customer/Dashboard.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import AccountInfoIcon from "../../../assets/Icons/info_account.png";
import AccountInfoIconActive from "../../../assets/Icons/info_account_active.png";
import DashboardIcon from "../../../assets/Icons/dashicon.png";
import DashboardIconActive from "../../../assets/Icons/dashiconactive.png";
import CalendarIcon from "../../../assets/Icons/calendar_icon.png";
import CalendarIconActive from "../../../assets/Icons/calendar_icon_active.png";
import PersonIcon from "../../../assets/Icons/person_white.png";
import PersonIconActive from "../../../assets/Icons/person_active.png";
import PetIcon from "../../../assets/Icons/pet_white.png";
import PetIconActive from "../../../assets/Icons/pet_active.png";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { GET_SINGLE_CLIENTS_URL } from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";

const CustomerDashboard = ({ changeBannerInfo }) => {
  const languageValue = useSelector(selectLanguage);
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const token = localStorage.getItem("user_token");
  const id = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  // Split the pathname into parts based on '/'
  const pathParts = pathname.split('/');

  // Get the last part of the pathname
  const lastPart = pathParts[pathParts.length - 1];


  const getCustomerInfo = () => {
    AXIOS_GET(GET_SINGLE_CLIENTS_URL + `${id}`, token)
      .then((res) => {
       if(!res.data?.firstname || !res.data?.lastname){
        navigate("/clientpreinfo");
       }
      
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <div className="clinic-dashboard-container">
      <Sidebar changeBannerInfo={changeBannerInfo} />

      <div style={{ flex: 1 }}>
        <nav className="mobo_nav_container customer-mobo-container">
          <ul>
            <li className={lastPart === "summary" ? "active":""}>
              <Link to={"/customer/dashboard/summary"}>
                <img src={DashboardIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === "account" ? "active":""}>
              <Link to={"/customer/dashboard/account"}>
                <img src={PersonIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === "customerclinicinfo" ? "active":""}>
              <Link to={"/customer/dashboard/customerclinicinfo"}>
                <img src={AccountInfoIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === "animals" ? "active":""}>
              <Link to={"/customer/dashboard/animals"}>
                <img src={PetIcon} alt="" srcset="" />
              </Link>
            </li>
            <li className={lastPart === "rendezvous" ? "active":""}>
              <Link to={"/customer/dashboard/rendezvous"}>
                <img src={CalendarIcon} alt="" srcset="" />
              </Link>
            </li>
          </ul>
        </nav>

        <nav className="tablet-menu">
          <h1>{userFirstname&&userFirstname} {userLastname&&userLastname}</h1>
          <ul>
            <li className={lastPart === "summary" ? "active":""}>
              <Link to={"/customer/dashboard/summary"}>
                <div className="img-container">
                  {lastPart === "summary" ? (
                    <img src={DashboardIconActive} alt="" srcset="" />
                  ) : (
                    <img src={DashboardIcon} alt="" srcset="" />
                  )}
                </div>
                <span>MON ESPACE</span>
              </Link>
            </li>
            <li className={lastPart === "account" ? "active":""}>
              <Link to={"/customer/dashboard/account"}>
                <div className="img-container">
                  {
                    lastPart === "account" ? (
                      <img src={PersonIconActive} alt="" srcset="" />
                    ) : (
                      <img src={PersonIcon} alt="" srcset="" />
                    )
                  }
                </div>
                <span>MON COMPTE</span>
              </Link>
            </li>
            <li className={lastPart === "customerclinicinfo" ? "active":""}>
              <Link to={"/customer/dashboard/customerclinicinfo"}>
                <div className="img-container">
                  {
                    lastPart === "customerclinicinfo" ? (
                      <img src={AccountInfoIconActive} alt="" srcset="" />
                    ) : (
                      <img src={AccountInfoIcon} alt="" srcset="" />
                    )
                  }
                </div>
                <span>MES INFORMATION</span>
              </Link>
            </li>
            <li className={lastPart === "animals" ? "active":""}>
              <Link to={"/customer/dashboard/animals"}>
                <div className="img-container">
                  {
                    lastPart === "animals" ? (
                      <img src={PetIconActive} alt="" srcset="" />
                    ) : (
                      <img src={PetIcon} alt="" srcset="" />
                    )
                  }
                </div>
                <span>MES ANIMAUX</span>
                
              </Link>
            </li>
            <li className={lastPart === "rendezvous" ? "active":""}>
              <Link to={"/customer/dashboard/rendezvous"}>
                <div className="img-container">
                  {
                    lastPart === "rendezvous" ? (
                      <img src={CalendarIconActive} alt="" srcset="" />
                    ) : (
                      <img src={CalendarIcon} alt="" srcset="" />
                    )
                  }
                </div>
                <span>MES RENDEZ-VOUS</span>
              </Link>
            </li>
          </ul>
        </nav>

        <Outlet />
      </div>

    </div>
  );
};

export default CustomerDashboard;
