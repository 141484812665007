import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ExplicitIcon from "@mui/icons-material/Explicit";

import {
  changeToEn,
  changeToFr,
  selectLanguage,
} from "../../features/LanguageSlice";

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const languageVal = useSelector(selectLanguage);
  const [selectedLang, setselectedLang] = useState(languageVal);
  const changeLanguage = (e) => {
    setselectedLang(e.target.value);
    if (selectedLang === "En") {
      dispatch(changeToFr());
    } else {
      dispatch(changeToEn());
    }
  };
  return (
    <>
      {selectedLang === "En" ? (
        <ExplicitIcon onClick={() => dispatch(changeToEn())} />
      ) : (
        <LanguageOutlinedIcon onClick={() => dispatch(changeToFr())} />
      )}

      <select onChange={changeLanguage} className="lgn_change">
        {/* {selectedLang === "En" && (
          <>
            <option value="En">English</option>
            <option value="Fr">Français</option>
          </>
        )} */}
        {selectedLang === "Fr" && (
          <>
            <option value="Fr">Français</option>
            {/* <option value="En">English</option> */}
          </>
        )}
      </select>
    </>
  );
};

export default LanguageSwitcher;
