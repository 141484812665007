import React, { useEffect, useState } from "react";
import RendezvousCard from "./Rendezvous/RendezvousCard";
import {
  DELETE_APPOINTMENTS_URL,
  GET_APPOINTMENTS_URL,
} from "../../../helper/Url";
import { AXIOS_DELETE, AXIOS_GET } from "../../../config/axios";
import ErrorToast from "../../../helper/Message/ErrorToast";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { ReactComponent as SearchIcon } from "../../../assets/Icons/searchPurple.svg";
import { ReactComponent as PersonIcon } from "../../../assets/Icons/personsolidPurple.svg"
import { ReactComponent as PersonOutlineIcon } from "../../../assets/Icons/personoutlinePurple.svg"
import { ReactComponent as CalendarIcon } from "../../../assets/Icons/calendarPurple.svg"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const Rendezvous = () => {
  const [appointments, setAppointments] = useState([]);
  const [allappointments, setAllappointments] = useState([]);
  const token = localStorage.getItem("user_token");
  const [displayfew,setDisplayfew]=useState(true)

  // filter
  const [animalName, setAnimalName] = useState("");
  const [providerName, setProviderName] = useState("");
  const [date, setDate] = useState();
  const [clientName, setClientName] = useState("");

  const [filtered, setFiltered] = useState([]); // [

  const getTodayAppontments = () => {
    AXIOS_GET(GET_APPOINTMENTS_URL + `?day=today`, token)
      .then((res) => {
        setAppointments(res?.data);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };
  const getAllAppontments = () => {
    AXIOS_GET(GET_APPOINTMENTS_URL, token)
      .then((res) => {
        setAllappointments(res?.data);
        setFiltered(
          filteredAppointments(
            res?.data,
            animalName,
            providerName,
            date,
            clientName
          )
        )
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const filteredAppointments = (appointments, name, provider, date, client) => {
      // .filter((appointment) => {
      //    return appointment.status.toLowerCase() !=="pending" && appointment.status.toLowerCase() !=="cancelled";
      //   })
    let filtered = appointments
      .filter(
        (appointment) =>
          !name ||
          appointment.species.toLowerCase().includes(name.toLowerCase())
      )
      .filter(
        (appointment) =>
          !provider ||
          (appointment.provider.firstname + " " + appointment.provider.lastname)
            .toLowerCase()
            .includes(provider.toLowerCase())
      )
      .filter((appointment) => !date || appointment.date.includes(date))
      .filter(
        (appointment) =>
          !client ||
          (appointment.Client.firstname + " " + appointment.Client.lastname)
            .toLowerCase()
            .includes(client.toLowerCase())
      );

    return filtered;
  };
  
  const deleteAppointment = (id) => {
    let conf = window.confirm(
      "Voulez-vous vraiment supprimer ce rendez-vous ?"
    );
    if (conf) {
      AXIOS_DELETE(DELETE_APPOINTMENTS_URL + `${id}`, "", token)
        .then((res) => {
          getTodayAppontments();
          getAllAppontments();
          SuccessToast("Rendez-vous supprimé avec succès");
        })
        .catch((error) => {
          ErrorToast(error);
        });
    }
  };

  useEffect(() => {
    getTodayAppontments();
    getAllAppontments();
    
  }, []);

  const resetFilter = ()=>{
     setAnimalName("");
     setClientName("");
     setDate("");
     setProviderName("");
     setDisplayfew(false);
     document.getElementById("datesearch").type = "text";
  }

  useEffect(() => {
    setFiltered(
      filteredAppointments(
        allappointments,
        animalName,
        providerName,
        date,
        clientName
      )
    );
  }, [animalName, providerName, date, clientName]);

  return (
    <div className="content-container">
       <h1 className="welcome-title ">
        Bienvenue <br />
        <span className="text_purple">Vos rendez-vous</span> en un clic
      </h1>
      <br />

      <div className="rendezvous-passes">
        <h1 className="rendezvous-title text_purple">Les rendez-vous du jour</h1>
        {appointments?.length > 0
          ? appointments.map((appointment, index) => {
              return (
                <RendezvousCard
                  key={index}
                  item={appointment}
                  deleteAppointment={deleteAppointment}
                />
              );
            })
          : <>
            <span classname="strong">Vous n’avez aucun rendez-vous aujourd’hui.
</span>
          </>}
      </div> 

      <div className="rendezvous-passes">
        <h1 className="rendezvous-title text_purple">Tous mes rendez-vous</h1>
         <div className="filter-container bg_purple_light">
          <h1 className="text_purple"> Recherche</h1>
          <div className="filter-item">
            <div className="search-inputs border_purple">
              <input
                className="border_purple"
                type="text"
                placeholder="Nom de l’animal"
                value={animalName}
                onChange={(e) => setAnimalName(e.target.value)}
              />
              <SearchIcon />
            </div>
            <div className="search-inputs border_purple">
              <input
              className="border_purple"
                type="text"
                placeholder="Vétérinaire"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
              />
              <PersonIcon />
            </div>
            <div className="search-inputs border_purple">
              <input
                className="border_purple"
                type="text"
                placeholder="Date du rendez-vous"
                onFocus={(e) => (e.target.type = "date")}
                value={date}
                onBlur={(e) => {if(e.target.value === "") e.target.type = "text"}}
                id="datesearch"
                onChange={(e) => setDate(e.target.value)}
              />
              <label htmlFor="datesearch">
               <CalendarIcon />
              </label>
            </div>
            <div className="search-inputs border_purple">
              <input
              className="border_purple"
                type="text"
                placeholder="Nom du propriétaire"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <PersonOutlineIcon />
            </div>
            <div className="search-inputs border_purple" style={{ background:"#eeeaf7",cursor:"pointer" }} onClick={resetFilter}>
              <input
                className=""
                type="button"
                placeholder="Effacer les filtres"
                value="Effacer les filtres"
                style={{ background:"#eeeaf7",cursor:"pointer"}}
              />
              <DeleteOutlineIcon/>
            </div>
          </div>
        </div> 
        {filtered?.length > 0
          ? filtered.map((appointment, index) => {
              return (
                <>
                  {/* if valiable displayfew is true disply only 4 */}

                  {displayfew && index<=3 &&
                    <RendezvousCard
                    key={index}
                    item={appointment}
                    deleteAppointment={deleteAppointment}
                    animalName={animalName}
                  />
                    
                  }

                  {/* if valiable displayfew is true disply only 4 */}

                  {!displayfew  &&
                    <RendezvousCard
                    key={index}
                    item={appointment}
                    deleteAppointment={deleteAppointment}
                    animalName={animalName}
                  />
                    
                  }
                
                </>
              );
            })
          :  <span classname="strong">Vous n’avez aucun rendez-vous.
          {allappointments?.length > 0 ? (
          <button className="btn_action" onClick={()=>resetFilter()}>VOIR PLUS DE RDV</button>
        ) : (
          ""
        )}
          </span>}
         {allappointments?.length > 4 && displayfew ? (
          <button className="btn_action" onClick={()=>setDisplayfew(false)}>VOIR PLUS DE RDV</button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Rendezvous;
