import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../features/LanguageSlice";
import Home from "../../components/client/Home";
import HomeContainer from "../../components/client/container/HomeContainer";

function HomePage() {
  const languageValue = useSelector(selectLanguage);
  let Title =
    languageValue === "En"
      ? "Trouvez un vétérinaire en un clic"
      : "Trouvez un vétérinaire en un clic";
  let Subtitle =
    languageValue === "En"
      ? "Prendre rendez-vous en ligne"
      : "Prendre rendez-vous en ligne";

  return (
    <div className="change-container">
      <HomeContainer Title={Title} Subtitle={Subtitle}>
        <Home />
      </HomeContainer>
    </div>
  );
}

export default HomePage;
