import React from 'react'
import TimeTable from './Components/gridTimetable/Timetable'

const GridTimeTable = ({serviceId}) => {

  return (
    <>
     <TimeTable header={true} starttime={'00:00'} serviceId={serviceId}/>
    </>
  )
}

export default GridTimeTable
