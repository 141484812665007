import React, { useEffect, useState } from "react";
import RendezvousCard from "./Rendezvous/RendezvousCard";
import {
  DELETE_APPOINTMENTS_URL,
  GET_APPOINTMENTS_URL,
  GET_APPOINTMENT_CLINIC_URL,
} from "../../../helper/Url";
import { AXIOS_DELETE, AXIOS_GET } from "../../../config/axios";
import ErrorToast from "../../../helper/Message/ErrorToast";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Upcoming } from "./Rendezvous/Upcoming";

const Rendezvous = () => {
  const [appointments, setAppointments] = useState([]);
  const [allappointments, setAllappointments] = useState([]);
  const token = localStorage.getItem("user_token");
  const id = localStorage.getItem("user_id");

  // filter
  const [animalName, setAnimalName] = useState("");
  const [providerName, setProviderName] = useState("");
  const [date, setDate] = useState();
  const [clientName, setClientName] = useState("");
  const navigate = useNavigate();
  const [filteredPending, setFilteredPending] = useState([]);
  const [filtered, setFiltered] = useState([]); // [

  // const getTodayAppontments = () => {
  //   AXIOS_GET(GET_APPOINTMENTS_URL + `?day=today`, token)
  //     .then((res) => {
  //       setAppointments(res?.data);
  //     })
  //     .catch((error) => {
  //       ErrorToast(error);
  //     });
  // };

  const getAllAppontments = () => {
    AXIOS_GET(GET_APPOINTMENT_CLINIC_URL, token)
      .then((res) => {
        setAllappointments(res?.data);
        filteredAppointments(res?.data);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const filteredAppointments = (appointments) => {
    let filtered = appointments.filter((appointment) => {
      return appointment;
    });
    setFiltered(filtered);
    return filtered;
  };

  const deleteAppointment = (id) => {
    let conf = window.confirm(
      "Voulez-vous vraiment supprimer ce rendez-vous ?"
    );
    if (conf) {
      AXIOS_DELETE(DELETE_APPOINTMENTS_URL + `${id}`, "", token)
        .then((res) => {
          getAllAppontments();
          SuccessToast("Rendez-vous supprimé avec succès");
        })
        .catch((error) => {
          ErrorToast(error);
        });
    }
  };

  useEffect(() => {
    getAllAppontments();
  }, []);

  // filter
  useEffect(() => {
    const fil = allappointments.filter((appointment) => {
      return appointment.status.toLowerCase() === "pending";
    });
    setFilteredPending(fil);
  }, [allappointments]);

  useEffect(() => {
    setFiltered(
      filteredAppointments(
        allappointments,
        animalName,
        providerName,
        date,
        clientName
      )
    );
  }, [animalName, providerName, date, clientName]);

  return (
    <div className="content-container customer-content-container">
      <ToastContainer />
      <h1 className="welcome-title">
        Bienvenue{" "}
        <span>
          {localStorage.getItem("user_firstname") &&
            localStorage.getItem("user_firstname")}{" "}
          {localStorage.getItem("user_lastname") &&
            localStorage.getItem("user_lastname")}
        </span>
      </h1>
      <h1 className="page-name">Mes rendez-vous en un clic</h1>

      <div className="rendezvous-passes">
        {filteredPending?.length > 0 ? (
          <Upcoming
            key={filteredPending.length - 1}
            itemNumber={filteredPending.length - 1}
            item={filteredPending[filteredPending.length - 1]}
            deleteAppointment={deleteAppointment}
          />
        ) : (
          <>
            <br />
            <div className="strong">Vous n’avez aucun rendez-vous.</div>
          </>
        )}

        {filteredPending?.length == 0 && (
          <button className="btn_action" onClick={() => navigate("/search")}>
            PRENDRE UN RENDEZ-VOUS
          </button>
        )}
      </div>

      <br />
      <br />
      <div className="rendezvous-passes">
        <h1 className="rendezvous-title">Tous mes rendez-vous</h1>
        {/* {filtered?.length > 0 && <div className="filter-container">
          <h1>Recherche</h1>
          <div className="filter-item">
            <div className="search-inputs">
              <input
                type="text"
                placeholder="Nom de l’animal"
                value={animalName}
                onChange={(e) => setAnimalName(e.target.value)}
              />
              <SearchIcon />
            </div>
            <div className="search-inputs">
              <input
                type="text"
                placeholder="Vétérinaire"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
              />
              <PersonIcon />
            </div>
            <div className="search-inputs">
              <input
                type="date"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <CalendarMonthIcon />
            </div>
            <div className="search-inputs">
              <input
                type="text"
                placeholder="Nom du propriétaire"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <PersonIcon />
            </div>
          </div>
        </div>} */}

        {filtered?.length > 0 ? (
          filtered.map((appointment, index) => {
            return (
              <>
                <RendezvousCard
                  key={index}
                  item={appointment}
                  deleteAppointment={deleteAppointment}
                  animalName={animalName}
                />
              </>
            );
          })
        ) : (
          <>
            <br />
            <div classname="strong">Vous n’avez pas de rendez-vous passés.</div>
          </>
        )}

        <button className="btn_action" onClick={() => navigate("/search")}>
          PRENDRE UN RENDEZ-VOUS
        </button>
      </div>
    </div>
  );
};

export default Rendezvous;
