import React from "react";
import "../../styles/client/home/NotFound.scss";

const NotFound = () => {
  return (
    <div className="notfound_container">
      <h1>Page introuvable</h1>
    </div>
  );
};

export default NotFound;
