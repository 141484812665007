import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";

// From Login

import { Link, useNavigate } from "react-router-dom";
import "../../styles/client/account/Login.scss";
import Google from "../../assets/Icons/logo googleg 48dp.png";
import tick from "../../assets/Icons/Vector.png";
import { set, useForm } from "react-hook-form";
import { AXIOS_POST } from "../../config/axios";
import { POST_GOOGLE_LOGIN, POST_LOGIN_URL } from "../../helper/Url";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

import Loader from "../../helper/Loader/Loader";
import Translate from "../../config/Translate";
import FormErrors from "../../helper/Message/FormErrors";
import { authPop } from "../../firebase";
import ErrorToast from "../../helper/Message/ErrorToast";
import SuccessToast from "../Message/SuccessToast";
import SuccessError from "../Message/SuccessError";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ openm = false, closeModel }) {
  const navigate = useNavigate();
  const [isSubmitted, setisSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    data.authType = 1;
    setisSubmitted(true);
    AXIOS_POST(POST_LOGIN_URL, data)
      .then((res) => {
        setisSubmitted(false);
        if(res.data?.isVerified == false){
          localStorage.setItem("user_email",data.email);
          ErrorToast(res?.data?.message);
          setTimeout(()=>navigate("/verify",{replace:true}),5000);
          return;
        }
        if (res.data?.accountType === 0) {
          localStorage.setItem("user_token", res.data?.token);
          localStorage.setItem("user_accountType", res.data?.accountType);
          localStorage.setItem("user_id", res.data?.user._id);
          localStorage.setItem("user_email", res.data?.user.email);
          localStorage.setItem("user_firstname", res.data?.user.firstname ? res.data?.user.firstname : "");
          localStorage.setItem("user_lastname", res.data?.user.lastname ? res.data?.user.lastname : "");
          localStorage.setItem("authType", "normal");
          navigate("/search/confirm-booking");
          handleClose();

        } else {
          SuccessError(
            "Vous ne pouvez pas réserver avec le compte de l’entreprise, veuillez réserver avec le compte client."
          );
        }
        handleClose();
      })
      .catch((error) => {
        ErrorToast(error);   
        setisSubmitted(false);
      });
  };
  
  const createnew = () => {
    localStorage.setItem("isnew", 1);
    navigate("/preclientregister")
    handleClose();
  };
  const googleAuth = async () => {
    const user = await authPop();
    let data = {};
    data.authType = 1;
    if (user?.accessToken?.length > 0) {
      AXIOS_POST(POST_GOOGLE_LOGIN, data, user.accessToken)
        .then((res) => {
          setisSubmitted(false);
          if (res.data?.accountType === 0) {
            localStorage.setItem("user_token", res.data?.token);
            localStorage.setItem("user_accountType", res.data?.accountType);
            localStorage.setItem("user_id", res.data?.user._id);
            localStorage.setItem("user_email", res.data?.user.email);
            localStorage.setItem("user_firstname", res.data?.user.firstname ? res.data?.user.firstname : "");
            localStorage.setItem("user_lastname", res.data?.user.lastname ? res.data?.user.lastname : "");
            localStorage.setItem("authType", "google");
            setisSubmitted(false);
            navigate("/search/confirm-booking");
            handleClose();
          } else {
            SuccessError(
              "Vous ne pouvez pas réserver avec le compte de l’entreprise, veuillez réserver avec le compte client."
            );
          }
          handleClose();
        })
        .catch((error) => {
          ErrorToast(error);
          setisSubmitted(false);
        });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    closeModel();
  };
  useEffect(() => {
    setOpen(openm);
  }, [openm]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="login-container">
            <ToastContainer />
            <div className="black login-wrapper">
              <h1 className="font-20">
                Vous avez déjà un{" "}
                <span className="main-color">oneclinic.net</span>
              </h1>
              <span className="main-color font-16">Connexion</span>
              <h1 className="black">
                <Translate Word={"header.Login"} />
              </h1>
              <div className="login_action_container">
                <div className="section1">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="lg-email">E-mail</label>
                    <input
                      id="lg-email"
                      type="text"
                      placeholder="Entrez votre e-mail"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <FormErrors>
                        <Translate Word={"page.register.email.error"} />
                      </FormErrors>
                    )}
                    <label htmlFor="lg-password">Mot de passe</label>
                    <input
                      id="lg-password"
                      type="password"
                      placeholder="Entrez votre mot de passe"
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <FormErrors>
                        <Translate Word={"page.register.password.error"} />
                      </FormErrors>
                    )}

                    {isSubmitted ? (
                      <button type="button">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button>
                        <Translate Word={"header.login"} />
                      </button>
                    )}
                  </form>
                  <div className="remember-forget">
                    <div className="checkbox-group">
                      <label className="checkbox_wrapper" htmlFor="checkbox1">
                        <div className="pet_checkbox"></div>
                        <span>Se souvenir de moi. </span>
                        <input
                          type="checkbox"
                          className="pet_hidden_check"
                          id="checkbox1"
                          hidden
                        />
                        <img src={tick} alt="" />
                      </label>
                    </div>
                    <Link to="/forget" className="forget_password">
                      <Translate Word={"page.forget.password"} />
                    </Link>
                  </div>

                  {/* <button className="other-login other-login1" onClick={facebookAuth}> <img src={Facebook} alt="" />Se connecter avec Facebook</button> */}
                  <button
                    className="other-login other-login2"
                    onClick={googleAuth}
                  >
                    {" "}
                    <img src={Google} alt="" />
                    Se connecter avec Google
                  </button>
                  <button onClick={createnew}>
                    {" "}
                    Créer un nouveau compte
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
