import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SuccessError = (error) => {

      toast.warning(error, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: 'colored',
      })
    
};

export default SuccessError ;
