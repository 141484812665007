import React from "react";
import "../../../styles/client/common/Footer.scss";
import "../../../assets/Logos/logo-black.png";
import FooterLogo from "../../../assets/Logos/bottom.png";
import HospitalIcon from "../../../assets/Icons/hospital.png";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className=" top-container flex-container">
        <div className="flex-column column-one column-width">
          <div className="img-container">
            <img src={FooterLogo} alt="one clinic footer logo" />
          </div>
          <div className="footer-section">
            <p>
              <span>
                En quelques clics, prenez rendez-vous pour votre animal avec le
                vétérinaire disponible le plus proche de chez-vous.
              </span>
              <br />
              <br />
              <span>
                Service disponible <span className="strong"> 24h/24</span> et{" "}
                <span className="strong">7j/7.</span>
              </span>
              <br />
              <br />
              <span className="strong">Simple, sécurisé et 100% gratuit.</span>
            </p>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">SUIVEZ-NOUS</h3>
            <div className="flex-container brands-container">
              <div className="flex-column brands-icon">
                <Link
                  target="_blank"
                  to="https://www.instagram.com/oneclic.vet39/"
                >
                  <i class="fa-brands fa-instagram"></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.facebook.com/oneclic.vet">
                  <i class="fa-brands fa-facebook-f"></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/oneclic-vet/"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link
                  target="_blank"
                  to="https://www.youtube.com/channel/UC0O2a6--nHRwtez4misTMsA"
                >
                  <i class="fa-brands fa-youtube"></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link
                  target="_blank"
                  to="https://www.tiktok.com/@oneclic.vet?lang=fr"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://x.com/Oneclicvet">
                  <i class="fa-brands fa-x-twitter"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-column column-width">
          <div className="footer-section">
            <div className="spacing"></div>
            <h3 className="footer-title">LIENS RAPIDES</h3>
            <NavLink
              to="https://oneclic.vet/login"
              className="footer-link"
              target="_blank"
            >
              Mon compte{" "}
            </NavLink>
            {/* <NavLink to="#" className="footer-link">Aidez-moi</NavLink> */}
            <NavLink
              to="https://info.oneclic.vet/"
              className="footer-link"
              target="_blank"
            >
              À propos de nous{" "}
            </NavLink>
            <NavLink
              to="https://info.oneclic.vet/veterinaire-contactez-nous/"
              className="footer-link"
              target="_blank"
            >
              Vétérinaire ? Contactez-nous{" "}
            </NavLink>
            <NavLink
              to="https://info.oneclic.vet/devenir-partenaire-publicitaire/"
              className="footer-link"
              target="_blank"
            >
              Devenir Partenaire publicitaire{" "}
            </NavLink>
          </div>
          <div className="footer-section web">
            <br></br>
            <h3 className="footer-title" style={{ paddingTop: "6px" }}>
              ABONNEZ-VOUS
            </h3>
            <form action="">
              <div className="send-input">
                <input type="text" placeholder="Entrez votre e-mail" />
                <button className="button">
                  <SendOutlinedIcon />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex-column column-width">
          <div className="footer-section">
            <div className="spacing"></div>
            <h3 className="footer-title" style={{ visibility: "hidden" }}>
              LIENS RAPIDES
            </h3>
            <NavLink
              to="https://info.oneclic.vet/nlpd/"
              className="footer-link"
              target="_blank"
            >
              Politique de confidentialité
            </NavLink>
            <NavLink
              to="https://info.oneclic.vet/cookies/"
              className="footer-link"
              target="_blank"
            >
              Gestion des cookies
            </NavLink>
            {/* <NavLink to="#" className="footer-link">
              Nous contacter
            </NavLink> */}
            <NavLink
              to="https://info.oneclic.vet/mentions-legales/"
              className="footer-link"
              target="_blank"
            >
              Mentions légales
            </NavLink>
            <NavLink
              to="https://info.oneclic.vet/faq/"
              className="footer-link"
              target="_blank"
            >
              FAQ
            </NavLink>
          </div>
          <div className="footer-section">
            <ul>
              <li style={{ alignItems: "start" }}>
                <img src={HospitalIcon} style={{ marginRight: "19px" }} />
                Site hébergé, fabriqué et géré en Suisse
              </li>
            </ul>
          </div>
          <div className="footer-section mobo">
            <h3 className="footer-title">ABONNEZ-VOUS</h3>
            <form action="">
              <div className="send-input">
                <input type="text" placeholder="Entrez votre adresse e-mail" />
                <button className="button">
                  <SendOutlinedIcon />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <hr />
      <div className="bottom-container">
        <h4>
          © Copyright 2023{" "}
          <span className="strong">
            {" "}
            <a
              href="http://www.ONECLIC.VET"
              className="strong"
              target="_blank"
              rel="noreferrer"
            >
              ONECLIC.VET{" "}
            </a>
          </span>{" "}
          Tous droits réservés by
          <span className="main-color strong"> www.coommunication.com </span> &
          <span className="main-color strong"> www.pme-kmu.com </span>-{" "}
          <NavLink
            to="https://info.oneclic.vet/mentions-legales/"
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: "400",
            }}
            target="_blank"
          >
            Mentions légales
          </NavLink>
        </h4>
      </div>
    </div>
  );
};

export default Footer;
