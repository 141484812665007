import React, { useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Fade from "react-reveal/Fade";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useEffect } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SINGLE_CLINIC_URL } from "../../../helper/Url";
import { selectUserInfo } from "../../../features/UserInfoSlice";

const Sidebar = ({ changeBannerInfo }) => {
  const [accSubmenu, setaccSubmenu] = useState(false);
  const [infoSubmenu, setinfoSubmenu] = useState(false);
  const [animalSubmenu, setanimalSubmenu] = useState(false);
  const [planSubmenu, setplanSubmenu] = useState(false);
  const navigate = useNavigate();
  const languageValue = useSelector(selectLanguage);
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();
  const location = useLocation();
  const { id } = useParams();
  const [facilityName, setFacilityName] = useState("");
  const token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  const localFacilityName = localStorage.getItem("user_facilityName");
  const selectUserinfoData = useSelector(selectUserInfo);


  const getClicInfo = () => {
    AXIOS_GET(GET_SINGLE_CLINIC_URL + `${user_id}`, token)
      .then((res) => {
        setFacilityName(res.data.facilityName);
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getClicInfo();
  }, [selectUserinfoData]);


  const menuNavigate = (path, title, subtitle) => {
    navigate(path);
    setTitle(title);
    setSubtitle(subtitle);
  };
  useEffect(() => {
    location.pathname === "/clinic/dashboard/summary" &&
      changeBannerInfo(
        languageValue === "En" ? "Mon espace" : "Mon espace",
        languageValue === "En" ? "" : ""
      );
    location.pathname === "/clinic/dashboard/account" &&
      changeBannerInfo(
        languageValue === "En" ? "Mon espace" : "Mon espace",
        languageValue === "En"
          ? ""
          : ""
      );
    location.pathname === "/clinic/dashboard/myclinicinfo" &&
      changeBannerInfo(
        languageValue === "En"
          ? "Mon espace"
          : "Mon espace",
        languageValue === "En"
          ? ""
          : ""
      );
    (location.pathname === "/clinic/dashboard/animals" || id > 0) &&
      changeBannerInfo(
        languageValue === "En" ? "Mon espace" : "Mon espace",
        languageValue === "En" ? "" : ""
      );
    location.pathname === "/clinic/dashboard/rendezvous" &&
      changeBannerInfo(
        languageValue === "En" ? "Mon espace" : "Mon espace",
        languageValue === "En" ? "" : ""
      );
  }, [location.pathname, languageValue]);
  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="sidebar-container bg_purple_strong">
      <div className="sticky_container">
        <h1>Clinique {facilityName}</h1> 
        <NavLink
          to={"/clinic/dashboard/summary"}
          className="menu-button menu_center" 
          onClick={() => {
            menuNavigate(
              "#",
              languageValue === "En" ? "Mon espace" : "Mon espace"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4>
              {languageValue === "En" ? "ESPACE" : "ESPACE"}
            </h4>
            <div></div>
          </div>
        </NavLink>
        <NavLink
          to={"/clinic/dashboard/account"}
          className="menu-button menu_center"
          onClick={() => {
            menuNavigate(
              "#",
              languageValue === "En" ? "COMPTE" : "COMPTE"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4> {languageValue === "En" ? "COMPTE" : "COMPTE"}</h4>
            <div></div>
          </div>
          {accSubmenu && (
            <div className="sub-menu">
              <Link to="#">
                <Fade bottom>
                  {languageValue === "En" ? "COMPTE" : "COMPTE"}
                </Fade>
              </Link>
              {/* <Link to="#">
              <Fade bottom>Profile</Fade>
            </Link> */}
            </div>
          )}
        </NavLink>
        <NavLink
          to={"/clinic/dashboard/myclinicinfo"}
          className="menu-button menu_center"
          onClick={() => {
            menuNavigate(
              "#",
              languageValue === "En" ? "INFORMATIONS" : "INFORMATIONS"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4>
              {languageValue === "En" ? "INFORMATIONS" : "INFORMATIONS"}
            </h4>
            <div></div>
          </div>
          {infoSubmenu && (
            <div className="sub-menu">
              <Link to="#">
                <Fade bottom>
                  {languageValue === "En"
                    ? "My Information"
                    : "Mes informations"}
                </Fade>
              </Link>
            </div>
          )}
        </NavLink>
        <NavLink
          to={"/clinic/dashboard/timetable"}
          className="menu-button menu_center"
          onClick={() => {
            menuNavigate(
              "/clinic/dashboard/timetable",
              languageValue === "En" ? "HORAIRES" : "HORAIRES"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4>{languageValue === "En" ? "HORAIRES" : "HORAIRES"}</h4>
            <div></div>
          </div>
          {animalSubmenu && (
            <div className="sub-menu">
              <Link to="#">
                <Fade bottom>
                  {languageValue === "En" ? "My animals" : "Mes animaux"}
                </Fade>
              </Link>
              {/* <Link to="#">
              <Fade bottom>Profile</Fade>
            </Link> */}
            </div>
          )}
        </NavLink>
        <NavLink
          to={"/clinic/dashboard/service"}
          className="menu-button menu_center"
          onClick={() => {
            menuNavigate(
              "/clinic/dashboard/service",
              languageValue === "En" ? "SERVICES" : "SERVICES"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4> {languageValue === "En" ? "SERVICES" : "SERVICES"}</h4>
            <div></div>
          </div>
        </NavLink>
        <NavLink
          to={"/clinic/dashboard/veterinary"}
          className="menu-button menu_center"
          onClick={() => {
            menuNavigate(
              "/clinic/dashboard/veterinary",
              languageValue === "En" ? "VÉTÉRINAIRES" : "VÉTÉRINAIRES"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4> {languageValue === "En" ? "VÉTÉRINAIRES" : "VÉTÉRINAIRES"}</h4>
            <div></div>
          </div>
        </NavLink>

        <NavLink
          className="menu-button menu_center"
          to={"/clinic/dashboard/rendezvous"}
          onClick={() => {
            menuNavigate(
              "/clinic/dashboard/rendezvous",
              languageValue === "En" ? "RENDEZ-VOUS" : "RENDEZ-VOUS"
            );
          }}
        >
          <div className="menu">
            <div></div>
            <h4>{languageValue === "En" ? "RENDEZ-VOUS" : "RENDEZ-VOUS"}</h4>
            <div></div>
          </div>
          {planSubmenu && (
            <div className="sub-menu">
              <Link to="#">
                <Fade bottom>
                  {languageValue === "En" ? "Appointment" : "Rendez-vous"}
                </Fade>
              </Link>
            </div>
          )}
        </NavLink>
        <div className="logout" onClick={logOut}>
          <LogoutIcon className="lg_svg" />{" "}
          <h4>{languageValue === "En" ? "Logout" : "Se déconnecter"}</h4>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
