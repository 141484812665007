import "../../../styles/client/account/Register.scss";
import ClinicContainer from "../../../components/client/container/ClinicContainer";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import ClinicDashboard from "../../../components/client/clinic/ClinicDashboard";
import { useEffect, useState } from "react";
import MobileRedirect from "../../../helper/MobileRedirect";
import { useNavigate } from "react-router-dom";
import Loader from "../../../helper/Loader/Loader";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SINGLE_CLINIC_URL } from "../../../helper/Url";

function ClinicDashboardPage() {
  const token = localStorage.getItem("user_token");
  const id = localStorage.getItem("user_id");
  const [infoAvailable, setInfoAvailable] = useState(false);
  const navigate = useNavigate();
  const languageValue = useSelector(selectLanguage);
  const [title, setTitle] = useState(
    languageValue === "En" ? "Mon espace" : "Mon espace"
  );
  const [subtitle, setSubtitle] = useState(
    languageValue === "En"
      ? ""
      : ""
  );
  let DefaultTitle = languageValue === "En" ? "Mon espace" : "Mon espace";
  let DefaultSubtitle =
    languageValue === "En"
      ? ""
      : "";

  const changeBannerInfo = (
    titlep = DefaultTitle,
    subtitlep = DefaultSubtitle
  ) => {
    setTitle(titlep);
    setSubtitle(subtitlep);
  };

  const getClicInfo = () => {
    AXIOS_GET(GET_SINGLE_CLINIC_URL + `${id}`, token)
      .then((res) => {
        if (!res.data?.firstname || !res.data?.lastname) {
          navigate("/companypreinfo");
        } else {
          setInfoAvailable(true);
        }
      

      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getClicInfo();
  }, []);


  return (
    <div className="account-page-container clinic-pages">
      <MobileRedirect/>
      <ClinicContainer
        Title={title}
        Subtitle={subtitle}
        ShowMap={false}
        ShowFooter={true}
      >
        {infoAvailable ? <ClinicDashboard changeBannerInfo={changeBannerInfo} /> :  <div className="check_basic_info">
            <Loader  color="#8b7bcb"/>
          </div>} 
        
      </ClinicContainer>
    </div>
  );
}

export default ClinicDashboardPage;
