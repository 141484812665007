import React from "react";
import SelectTime from "./SelectTime.js";

const TimeTableSettings = ({entId=null}) => {
  return (
    <div className="timetable_setting_container">
      <SelectTime header={true} entId={entId}/>
    </div>
  );
};

export default TimeTableSettings;
