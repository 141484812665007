// MobileRedirect.js
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from "@mui/material";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 5,
    borderColor: 'primary.main',
    boxShadow: 24,
    color:"black",
    p: 4,
  };

const MobileRedirect = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      // Display alert using Material-UI Dialog
      setOpen(true);
    }else{
        setOpen(false);
    }
  }, [isMobile]);

  const handleClose = () => {
    setOpen(false);
    // Redirect to root ("/")
    navigate('/');
    // Clear local storage
    localStorage.clear();
  };

  return (
    <Modal open={open} onClose={handleClose}>
    <Box sx={style}>
    <div>
    <p>Chère clinique,</p>
    <p>Oneclic.vet n’est pas disponible sur mobile.</p>
    <p>Nous vous recommandons d’utiliser notre outil via votre ordinateur.</p>
    <p>Bien à vous,</p>
    <p>L’équipe Oneclic.vet</p>

    </div>
    <div className="info-actio-btn">
    <button style={{width:"300px"}} onClick={handleClose}>Retour à l’accueil</button>
    </div>
    </Box>
   </Modal>
  );
};

export default MobileRedirect;
