import React from "react";
import SelectTime from "./SelectTime.js";

const TimeTableSettings = () => {
  return (
    <div className="timetable_setting_container">
      <SelectTime header={true} />
    </div>
  );
};

export default TimeTableSettings;
