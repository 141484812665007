import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect } from "react";
import Loader from "../../../../helper/Loader/Loader";
import { KeyboardArrowDown } from "@mui/icons-material";

export const Timetable = ({
  slots,
  id,
  viewmore = 1,
  handlebooking,
  onretrieve = null,
  resetselectedDate,
  passedLoader,
  weekTimeTable,
  sysWidthy,
  item,
}) => {
  const [passedSlots, setPassedSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weekNumberDays, setWeekNumberDays] = useState(0);
  const [todayDate, setTodayDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [weekday, setweekday] = useState(0);
  const [Tyears, setTyears] = useState([]);

  const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  const nextDay = (index) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    if (onretrieve != null) {
      const nextDate = new Date(
        new Date().getTime() + 24 * 60 * 60 * 1000 * (index + weekday)
      );
      return nextDate.toLocaleDateString("fr-FR", options).replace(/\//g, "-");
    } else {
      const nextDate = new Date(
        new Date().getTime() +
          24 * 60 * 60 * 1000 * weekday +
          24 * 60 * 60 * 1000 * index
      );
      return nextDate.toLocaleDateString("fr-FR", options).replace(/\//g, "-");
    }
  };

  // track if nextweek or prevweek is clicked

  useEffect(() => {
    // week day is greater than 0, then next week is clicked get today date and add 7 days to it and console it out
    const date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * weekday)
      .toISOString()
      .slice(0, 10);
    // next week start date and day
    const nextweek = new Date(
      new Date().getTime() + 24 * 60 * 60 * 1000 * weekday
    ).getDay();
    weekTimeTable(id, date);
    // Reset expanded days when changing weeks
    setExpandedDays({});
    // Reset time range when changing weeks
    setTimeRangeIndex(0);
  }, [weekday]);

  // check if passed vet id is not null or service id is not null and the set weekday to 0

  // useEffect(() => {
  //   if (vetId!=null || serviceId!=null || id!=null){
  //     setweekday(0);
  //   }
  // }, [vetId, serviceId,id]);

  const currentDay = new Date().getDay();

  const [sortedDays, setSortedDays] = useState([]);
  useEffect(() => {
    const slotsCopy = passedSlots.slice(); // Create a copy of slots
    const sorting = slotsCopy.sort((a, b) => {
      const adjustedA =
        (a.dayOfWeek === 7 ? 0 : a.dayOfWeek - currentDay + 7) % 7;
      const adjustedB =
        (a.dayOfWeek === 7 ? 0 : a.dayOfWeek - currentDay + 7) % 7;
      return adjustedA - adjustedB;
    });
    setSortedDays(sorting);
  }, [passedSlots]);

  useEffect(() => {
    picktime();
    resetselectedDate();
    if (onretrieve != null) {
      console.log("onretrieve is not null", onretrieve);
      setPassedSlots(onretrieve);
    } else {
      console.log("onretrieve is null", onretrieve);
      setPassedSlots(slots);
    }
  }, [passedSlots, onretrieve]);

  // change loader based on passed in loader
  useEffect(() => {
    setLoading(passedLoader);
  }, [passedLoader]);

  const getFormattedDateAndUniqueYears = (date) => {
    const options = { day: "2-digit", month: "short" };
    const [day, month, year] = date.split("-");
    const months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aoû",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ];
    const formattedDate = new Date(
      `${year}-${month}-${day}`
    ).toLocaleDateString("fr-FR", options);

    if (!Tyears.includes(year)) {
      setTyears([...Tyears, year]);
    }

    return `${day} ${months[parseInt(month) - 1]} ${year}`;
  };

  // check if today date is same as the passed in date

  const isToday = (date) => {
    const dateArray = date.split("-");
    const dateString = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`; // Rearrange parts for YYYY-MM-DD
    return dateString === todayDate;
  };

  const toIsoFormat = (date) => {
    const dateString = date.split("-");
    const isoDateString = `${dateString[2]}-${dateString[1]}-${dateString[0]}`; // Rearrange parts for YYYY-MM-DD

    const dateObject = new Date(isoDateString);
    const isoString = dateObject.toISOString();

    return isoString;
  };

  // date,time,startTimeObj,endTimeObj,day
  const picktime = (date, stime, startTimeObj, endTimeObj, day, e) => {
    document.querySelectorAll(".raw").forEach((item) => {
      item?.classList?.remove("active");
    });
    e?.target?.classList?.toggle("active");
    handlebooking(id, date, stime, startTimeObj, endTimeObj, day);
  };

  const [visibleSlots, setVisibleSlots] = useState(5);
  const [showViewMore, setShowViewMore] = useState(true);

  // Add this state to track expanded slots for each day
  const [expandedDays, setExpandedDays] = useState({});

  // Add this state to track which time range we're viewing
  const [timeRangeIndex, setTimeRangeIndex] = useState(0);

  // Get operating hours from item or use defaults
  const startHour = item?.operatingHours?.startTime?.hours ?? 7;
  const endHour = item?.operatingHours?.endTime?.hours ?? 17;

  // Generate array of hours based on operating hours
  const loopHours = Array.from(
    { length: endHour - startHour + 1 },
    (_, i) => `${(startHour + i).toString().padStart(2, '0')}:00`
  );

  const getVisibleHours = () => {
    const slotsPerGroup = 5;
    const startIndex = (viewmore - 1) * slotsPerGroup;
    const endIndex = startIndex + slotsPerGroup;
    return loopHours.slice(startIndex, endIndex);
  };

  const hasMoreSlots = () => {
    const slotsPerGroup = 5;
    const currentEndIndex = viewmore * slotsPerGroup;
    return currentEndIndex < loopHours.length;
  };

  return (
    <div className="calendar">
      <div
        className="column1"
        onClick={() =>
          weekday > 0
            ? sysWidthy < 800
              ? setweekday(weekday - 3)
              : setweekday(weekday - 5)
            : null
        }
      >
        <KeyboardArrowLeftIcon />
      </div>
      <div className="column2">
        {loading && (
          <div className="loader">
            <Loader color="white" />
          </div>
        )}

        {sysWidthy < 800
          ? // Mobile view: 3 columns
            Array.from({ length: 3 }).map((_, index) => (
              <div className="day" key={index}>
                <div className="header">
                  <h5>
                    {days[new Date(toIsoFormat(nextDay(index))).getDay()]}
                  </h5>
                  <h6>{getFormattedDateAndUniqueYears(nextDay(index))}</h6>
                </div>

                {getVisibleHours().map((hour, i) => {
                  const hourNum = parseInt(hour);
                  
                  // Get the date for the current column
                  const currentDate = nextDay(index);
                  
                  // Convert to ISO format for comparison
                  const currentISODate = toIsoFormat(currentDate);
                  
                  // Find the matching slot data for this date
                  const daySlotData = onretrieve?.find(slot => 
                    slot.date.split('T')[0] === currentISODate.split('T')[0]
                  );
                  
                  // Check if we have valid slots for this day
                  const hasValidSlots = daySlotData?.slots && Array.isArray(daySlotData.slots);
                  
                  const timePresent = hasValidSlots
                    ? daySlotData.slots.find(
                        (slot) => slot.start.hours === hourNum
                      )
                      ? {
                          status: true,
                          start: daySlotData.slots.find(
                            (slot) => slot.start.hours === hourNum
                          ).start,
                          end: daySlotData.slots.find(
                            (slot) => slot.start.hours === hourNum
                          ).end,
                        }
                      : { status: false }
                    : { status: false };

                  // If no valid slots for this day or it's today, show inactive slot
                  if (!hasValidSlots || isToday(currentDate)) {
                    return (
                      <div key={`${index}-${hour}`} className="raw inactive">
                        -
                      </div>
                    );
                  }

                  return timePresent.status ? (
                    <div
                      key={`${index}-${hour}`}
                      className="raw"
                      onClick={(e) =>
                        picktime(
                          currentDate,
                          hour,
                          timePresent.start,
                          timePresent.end,
                          index,
                          e
                        )
                      }
                    >
                      {hour}
                    </div>
                  ) : (
                    <div key={`${index}-${hour}`} className="raw inactive">
                      -
                    </div>
                  );
                })}
              </div>
            ))
          : // Desktop view: exactly 5 columns
            Array.from({ length: 5 }).map((_, index) => (
              <div className="day" key={index}>
                <div className="header">
                  <h5>
                    {days[new Date(toIsoFormat(nextDay(index))).getDay()]}
                  </h5>
                  <h6>{getFormattedDateAndUniqueYears(nextDay(index))}</h6>
                </div>

                {getVisibleHours().map((hour, i) => {
                  const hourNum = parseInt(hour);
                  
                  // Get the date for the current column
                  const currentDate = nextDay(index);
                  
                  // Convert to ISO format for comparison
                  const currentISODate = toIsoFormat(currentDate);
                  
                  // Find the matching slot data for this date
                  const daySlotData = onretrieve?.find(slot => 
                    slot.date.split('T')[0] === currentISODate.split('T')[0]
                  );
                  
                  // Check if we have valid slots for this day
                  const hasValidSlots = daySlotData?.slots && Array.isArray(daySlotData.slots);
                  
                  const timePresent = hasValidSlots
                    ? daySlotData.slots.find(
                        (slot) => slot.start.hours === hourNum
                      )
                      ? {
                          status: true,
                          start: daySlotData.slots.find(
                            (slot) => slot.start.hours === hourNum
                          ).start,
                          end: daySlotData.slots.find(
                            (slot) => slot.start.hours === hourNum
                          ).end,
                        }
                      : { status: false }
                    : { status: false };

                  // If no valid slots for this day or it's today, show inactive slot
                  if (!hasValidSlots || isToday(currentDate)) {
                    return (
                      <div key={`${index}-${hour}`} className="raw inactive">
                        -
                      </div>
                    );
                  }

                  return timePresent.status ? (
                    <div
                      key={`${index}-${hour}`}
                      className="raw"
                      onClick={(e) =>
                        picktime(
                          currentDate,
                          hour,
                          timePresent.start,
                          timePresent.end,
                          index,
                          e
                        )
                      }
                    >
                      {hour}
                    </div>
                  ) : (
                    <div key={`${index}-${hour}`} className="raw inactive">
                      -
                    </div>
                  );
                })}
              </div>
            ))}
      </div>
      <div
        className="column3"
        onClick={() =>
          sysWidthy < 800 ? setweekday(weekday + 3) : setweekday(weekday + 5)
        }
      >
        <KeyboardArrowRightIcon />
      </div>
    </div>
  );
};
