import "../../../styles/client/account/Register.scss";
import ClientContainer from "../../../components/client/container/Container";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import ClientRegister from "../../../components/client/account/Register/ClientRegister";

function RegisterPage() {
  const languageValue = useSelector(selectLanguage);

  let Title = languageValue === "En" ? "Inscription" : "Inscription";
  let Subtitle =
    languageValue === "En"
      ? "Votre rendez-vous vétérinaire en un clic"
      : "Votre rendez-vous vétérinaire en un clic";

  return (
    <div className="account-page-container">
      <ClientContainer Title={Title} Subtitle={Subtitle}>
        <ClientRegister />
      </ClientContainer>
    </div>
  );
}

export default RegisterPage;
