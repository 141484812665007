import React from "react";
import DogIcon from "../../../assets/Icons/dog-icon.png";
import GenderIcon from "../../../assets/Icons/gender-icon.png";
import CakeIcon from "../../../assets/Icons/cake-icon.png";
import NeuteIcon from "../../../assets/Icons/neutered-icon.png";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import {
  AXIOS_DELETE,
  AXIOS_GET,
  AXIOS_POST,
  AXIOS_PUT,
} from "../../../config/axios";
import {
  DELETE_PETS_URL,
  GET_SINGLE_PET_URL,
  POST_PETS_URL,
  POST_PET_PICTURE_URL,
  UPDATE_PETS_URL,
} from "../../../helper/Url";
import { Router, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../helper/Loader/Loader";
import SuccessToast from "../../../helper/Message/SuccessToast";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { Base_Url } from "../../../config/variables";
import { ToastContainer } from "react-toastify";
import { set } from "react-hook-form";
import { Data } from "@react-google-maps/api";
import AddAPhotoIcon from "../../../assets/Icons/Add-icon.png";
import FormErrors from "../../../helper/Message/FormErrors";
import { useForm } from "react-hook-form";
import { setAnimalEdited } from "../../../features/AnimalsListChange";
import { useDispatch } from "react-redux";

const AnimalDetail = () => {
  const [base64String, setbase64String] = useState("");
  const token = localStorage.getItem("user_token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [sterilize, setSterilize] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handlesubmit1,
    register: register1,
    setValue: setvalue1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();
  const {
    handleSubmit: handlesubmit2,
    register: register2,
    setValue: setvalue2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const getAnimal = () => {
    AXIOS_GET(GET_SINGLE_PET_URL + `${id}`, token)
      .then((res) => {
        setFormData(res.data);
        res.data.sterilize
          ? setSterilize(res.data.sterilize)
          : setSterilize(false);
        Object.keys(res.data).forEach((key) => {
          if (key === "birthdate") {
            setValue(key, res.data[key].slice(0, 10));
            return;
          }
          if (key === "nextVaccinationDate") {
            setvalue1(key, res.data[key].slice(0, 10));
            return;
          }
          if (key === "reminderDate") {
            setvalue1(key, res.data[key].slice(0, 10));
            return;
          }

          // set value for form one
          if (
            key === "name" ||
            key === "sex" ||
            key === "species" ||
            key === "breed" ||
            key === "color" ||
            key === "birthdate"
          ) {
            setValue(key, res.data[key]);
          }
          // set value for form two
          if (
            key === "puceNumber" ||
            key === "isInsured" ||
            key === "nextVaccinationDate" ||
            key === "reminderDate" ||
            key === "medicalHistory" ||
            key === "pathologies"
          ) {
            setvalue1(key, res.data[key]);
          }
          // set value for form three
          if (key === "allergies" || key === "isAnimalOut") {
            setvalue2(key, res.data[key]);
          }

          // if key is sterilize then set the value of sterilize
          if (key === "sterilize") {
            setSterilize(res.data[key]);
          }
        });
      })
      .catch((error) => {});
  };
  const deleteAnimal = () => {
    let conf = window.confirm("Voulez-vous vraiment supprimer cette fiche ?");

    if (conf) {
      AXIOS_DELETE(DELETE_PETS_URL + `${id}`, "", token)
        .then((res) => {
          SuccessToast("Animal supprimé avec succès");
          setTimeout(() => {
            navigate("/customer/dashboard/animals/");
          }, 2000);
        })
        .catch((error) => {
          ErrorToast(error);
        });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    setvalue1(name, value);
    setvalue2(name, value);
  };

  const onhandleSubmit = (data) => {
    data.sterilize = sterilize;
    setLoading(true);
    AXIOS_PUT(UPDATE_PETS_URL + `${id}`, data, token)
      .then((res) => {
        setLoading(false);
        getAnimal();
        dispatch(setAnimalEdited());
        SuccessToast("Animal modifié avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      });
  };
  const onhandleSubmit1 = (data) => {
    data.isInsured == "true"
      ? (data.isInsured = true)
      : (data.isInsured = false);

    setLoading2(true);
    AXIOS_PUT(UPDATE_PETS_URL + `${id}`, data, token)
      .then((res) => {
        getAnimal();
        setLoading2(false);
        dispatch(setAnimalEdited());
        SuccessToast("Animal modifié avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading2(false);
      });
  };
  const onhandleSubmit2 = (data) => {
    // validate make sure that the required fields in description are filled and if not show error message

    data.isAnimalOut == "true"
      ? (data.isAnimalOut = true)
      : (data.isAnimalOut = false);

    setLoading3(true);
    AXIOS_PUT(UPDATE_PETS_URL + `${id}`, data, token)
      .then((res) => {
        setLoading3(false);
        getAnimal();
        dispatch(setAnimalEdited());
        SuccessToast("Animal modifié avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading3(false);
      });
  };

  const editPicture = (id, e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };
    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
    AXIOS_POST(POST_PET_PICTURE_URL + `${id}`, formdata, token)
      .then((res) => {
        navigate(0);
        SuccessToast("Photo modifiée avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  function calculatePetAge(birthdate) {
    const birthDateObject = new Date(birthdate);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const ageInMilliseconds = currentDate - birthDateObject;

    // Convert milliseconds to years, months, and days
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
    const years = Math.floor(ageInMilliseconds / millisecondsInYear);
    const remainingMilliseconds = ageInMilliseconds % millisecondsInYear;
    const months = Math.floor(
      remainingMilliseconds / (1000 * 60 * 60 * 24 * (365.25 / 12))
    );
    const days = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24));

    // Construct the age string
    let ageString = "";
    if (years > 0) {
      ageString += years + " ans ";
    }
    if (months > 0) {
      ageString += months + " mois ";
    }
    if (days > 0 && years === 0) {
      ageString += days + " jours ";
    }

    return ageString.trim();
  }

  useEffect(() => {
    getAnimal();
    reset();
    reset1();
    reset2();
  }, [id]);

  return (
    <div className="content-container customer-content-container">
      <ToastContainer />
      <h1 className="welcome-title">Mes animaux</h1>
      <h1 className="">La fiche de <span className="page-name sp-header"> {formData?.name}</span></h1>
      <div className="pet-profile">
        <div className="profile-img-container">
          <label htmlFor="petAnimal">
            <img
              htmlFor="petAnimal"
              style={{ cursor: "pointer" }}
              src={
                formData?.picture
                  ? formData?.picture.includes("http")
                    ? formData.picture
                    : Base_Url + `/${formData?.picture}`
                  : "https://placehold.co/400x400"
              }
              alt=""
              className={formData?.picture ? "" : "add-photo-animal"}
            />
            <div
              className="fnt-14"
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {" "}
              Modifier
            </div>
          </label>
          <label
            htmlFor="petAnimal"
            style={{
              display: "flex",
              gap: "5px",
              cursor: "pointer",
              textAlign: "center",
              color: "#2cb5b2",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {/* <span> Ajouter une photo</span> */}
          </label>
          <input
            className="margin-bottom-26"
            name=""
            type="file"
            hidden
            id="petAnimal"
            onChange={(e) => editPicture(formData._id, e)}
          />
        </div>
        <div className="profile-data">
          <ul>
            <li>
              <img src={GenderIcon} alt="" />
              <span>{formData?.sex}</span>
            </li>
            <li>
              <img src={DogIcon} alt="" />
              <span>{formData?.breed}</span>
            </li>
            <li>
              <img src={CakeIcon} alt="" />
              <span>
                {calculatePetAge(new Date(formData.birthdate)) || "0 ans"}
              </span>
            </li>
            <li>
              <img src={NeuteIcon} alt="" />
              <span>
                {formData?.sex === "Femelle" && sterilize
                  ? "Stérilisée"
                  : sterilize && formData?.sex === "Mâle"
                  ? "Stérilisé"
                  : formData?.sex === "Femelle" ? "Non stérilisée" : "Non stérilisé"}
              </span>
            </li>
            {/* uploader */}
            {/* <li>
              <img src={AddAPhotoIcon} alt="" />
              <label
                htmlFor="petAnimal"
                style={{ color: "#2a2a2a", cursor: "pointer" }}
              >
                <span className="fnt-14">Ajouter une photo</span>
              </label>
              <input
                className="margin-bottom-26"
                name=""
                type="file"
                hidden
                id="petAnimal"
                onChange={(e) => editPicture(formData._id, e)}
              />
            </li> */}
          </ul>
        </div>
      </div>
      <div className="change-form">
        <form onSubmit={handleSubmit(onhandleSubmit)}>
          <h1 className="form-subtitle">Description de {formData?.name}</h1>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Nom*</label>
              <input
                className="margin-bottom-26"
                name="name"
                onChange={handleInputChange}
                {...register("name", { required: true })}
                type="text"
                placeholder="Nom de l’animal*"
              />
              {errors.name?.type === "required" && (
                <FormErrors>Le nom de l'animal est obligatoire</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Sexe*</label>
              <select
                className="margin-bottom-26"
                onChange={handleInputChange}
                name="sex"
                {...register("sex", { required: true })}
                id=""
              >
                <option value="" selected disabled>
                  Sexe de l’animal*
                </option>
                <option value="Femelle">Femelle</option>
                <option value="Mâle">Mâle</option>
              </select>
              {errors.sex?.type === "required" && (
                <FormErrors>Le sexe de l'animal est obligatoire</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Espèce*</label>
              <select
                className="margin-bottom-26"
                onChange={handleInputChange}
                name="species"
                {...register("species", { required: true })}
                id=""
              >
                <option value="" selected disabled>
                  Espèce de l’animal*
                </option>
                <option value="Chat">Chat</option>
                <option value="Chien">Chien</option>
                <option value="NAC">NAC</option>
              </select>
              {errors.species?.type === "required" && (
                <FormErrors>L'espèce de l'animal est obligatoire</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Race*</label>
              <input
                className="margin-bottom-26"
                name="breed"
                onChange={handleInputChange}
                {...register("breed", { required: true })}
                type="text"
                placeholder=" Race de l’animal*"
              />
              {errors.breed?.type === "required" && (
                <FormErrors>Race de l'animal est obligatoire</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Couleur</label>
              <input
                className="margin-bottom-26"
                name="color"
                onChange={handleInputChange}
                {...register("color")}
                type="text"
                placeholder="Couleur de l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Date de naissance de l’animal*</label>
              <input
                className="margin-bottom-26"
                name="birthdate"
                onChange={handleInputChange}
                {...register("birthdate", { required: true })}
                type="date"
                placeholder="Date de naissance de l’animal*"
              />
              {errors.birthdate?.type === "required" && (
                <FormErrors>
                  La date de naissance de l'animal est obligatoire
                </FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container  gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <div className="label-check">
                <span className="text-data">L’animal est-il stérilisé ?</span>
                <div className="input-container checkbox-global">
                  <div className="checkbox_box">
                    <input
                      id="checkBox"
                      name="sterilize"
                      checked={sterilize}
                      onChange={(e) => setSterilize(e.target.checked)}
                      type="checkbox"
                      hidden
                    />
                    <label htmlFor="checkBox">
                      <div className="disk"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-container"></div>
          </div>
          <div className="input-container btn-container">
            {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les modifications</button>
            )}
          </div>
        </form>
        <form onSubmit={handlesubmit1(onhandleSubmit1)}>
          <h1 className="form-subtitle">
            Informations médicales de {formData?.name}
          </h1>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Numéro de la puce</label>
              <input
                className="margin-bottom-26"
                name="puceNumber"
                onChange={handleInputChange}
                {...register1("puceNumber")}
                type="text"
                placeholder="Numéro de la puce de l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">L’animal est-il assuré ?</label>
              <select
                className="margin-bottom-26"
                onChange={handleInputChange}
                name="isInsured"
                {...register1("isInsured")}
                id=""
              >
                <option value="" selected disabled>
                  Assuré ?
                </option>
                <option value={"false"}>Non</option>
                <option value={"true"}>Oui</option>
              </select>
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Date de la dernière vaccination*</label>
              <input
                className="margin-bottom-26"
                name="nextVaccinationDate"
                onChange={handleInputChange}
                {...register1("nextVaccinationDate", { required: true })}
                type="date"
              />
              {errors1.nextVaccinationDate?.type === "required" && (
                <FormErrors>
                  La date de la dernière vaccination est obligatoire
                </FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Date de rappel du vaccin de la rage*</label>
              <input
                className="margin-bottom-26"
                name="reminderDate"
                onChange={handleInputChange}
                {...register1("reminderDate", { required: true })}
                type="date"
              />
              {errors1.reminderDate?.type === "required" && (
                <FormErrors>
                  La date de rappel du vaccin de la rage est obligatoire
                </FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Antécédents médicaux</label>
              <textarea
                name="medicalHistory"
                onChange={handleInputChange}
                {...register1("medicalHistory")}
                placeholder="L’animal a-t-il des antécédents médicaux ou chirurgicaux ? 
                Si oui, lesquels ?"
              ></textarea>
            </div>
            <div className="input-container">
              <label htmlFor="">
                L’animal a-t-il des pathologies ? Si oui, lesquelles ?
              </label>
              <textarea
                name="pathologies"
                onChange={handleInputChange}
                {...register1("pathologies")}
                placeholder="L’animal a-t-il des pathologies ? Si oui, lesquelles ?"
              ></textarea>
            </div>
          </div>
          <div className="input-container btn-container">
            {loading2 ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les modifications</button>
            )}
          </div>
        </form>
        <form onSubmit={handlesubmit2(onhandleSubmit2)}>
          <h1 className="form-subtitle">Mode de vie de {formData?.name}</h1>

          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Nourriture donnée à l’animal</label>
              <input
                className="margin-bottom-26"
                name="allergies"
                onChange={handleInputChange}
                {...register2("allergies")}
                type="text"
                placeholder="Nourriture donnée à l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">L’animal sort-il ?</label>
              <select
                className="margin-bottom-26"
                onChange={handleInputChange}
                name="isAnimalOut"
                id=""
                {...register2("isAnimalOut")}
              >
                <option value="" selected disabled>
                  L’animal sort-il ?
                </option>
                <option value={"false"}>Non</option>
                <option value={"true"}>Oui</option>
              </select>
            </div>
          </div>
          <div className="input-container btn-container">
            {loading3 ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les modifications</button>
            )}
          </div>
        </form>
      </div>
      <div className="delete-account" onClick={deleteAnimal}>
        <DeleteForeverIcon />
        Supprimer la fiche de cet animal
      </div>
    </div>
  );
};

export default AnimalDetail;
