import React, { useEffect } from 'react'
import Vector from "../../../../../../../assets/Icons/Vector_pupple.png"
import Redcross from "../../../../../../../assets/Icons/redcross.png"
import { useState } from 'react'
import { PATCH_PROVIDER_SLOT_DURATION_URL, UPDATE_PROVIDER_TIMETABLE_DISABLE_SLOT_URL, UPDATE_PROVIDER_TIMETABLE_ENABLE_SLOT_URL } from '../../../../../../../helper/Url'
import { AXIOS_PATCH } from '../../../../../../../config/axios'
import ErrorToast from '../../../../../../../helper/Message/ErrorToast'

const Slot = ({daystatus,status,weekday,slot,slotStatus,providerId}) => {
 
const [activateallslot,setactivateallslot]=useState(slotStatus)
const token = localStorage.getItem('user_token')
const [activeArray,setactiveArray]=useState(slot.active)

 // Disable specific slot 
 const disableslot = ()=>{
  AXIOS_PATCH(UPDATE_PROVIDER_TIMETABLE_DISABLE_SLOT_URL+`${weekday}/${providerId}`,slot,token)
  .then(res=>{
  })
  .catch(error=>{
    ErrorToast(error)
  })
 }

  // Enable specific slot
  const enableslot = ()=>{
    AXIOS_PATCH(UPDATE_PROVIDER_TIMETABLE_ENABLE_SLOT_URL+`${weekday}/${providerId}`,slot,token)
    .then(res=>{
    })
    .catch(error=>{
      ErrorToast(error)
    })
   }
    
    const activateAll = ()=>{
        setactivateallslot(!activateallslot);
        !activateallslot ? enableslot(): disableslot()
    }

    const disactivateslot = (num,e)=>{
      // check if all slot are active first 

      if(activateallslot){
        if(activeArray.includes(num)){
          const index = activeArray.indexOf(num);
          if (index > -1) {
              activeArray.splice(index, 1);
            }
          }
      else{
          activeArray.push(num)
      }
      setactiveArray([...activeArray])
      patchslot(activeArray);

      // if activearray length is 0 then disable slot else enable slot 
      if(activeArray.length == 0){
          setactivateallslot(false)
          disableslot()
      }else{
          enableslot()
      }}
      else{
        if(!activeArray.includes(num)){
          activeArray.push(num)
          patchslot(activeArray);
        }
        enableslot()
        setactivateallslot(true);
      }  
  }

  // patch slot active array

  const patchslot = (activeday)=>{
    let  data = slot;
    data.active = activeday;
    AXIOS_PATCH(PATCH_PROVIDER_SLOT_DURATION_URL+`${weekday}/${providerId}`,data,token)
    .then(res=>{
    })
    .catch(error=>{
      ErrorToast(error)
    })
  }


    const slotbutton = [];
    for (let index = 1; index <= 2; index++) {
            slotbutton.push(<button key={index} onClick={(e)=>disactivateslot(index,e)} className={daystatus == false ?  'disponible': daystatus && activateallslot && activeArray.includes(index)&& 'activate'}></button>)
    }
    return (
        <div className="slot">
        {slotbutton}
        {daystatus?<button className='indicater' onClick={activateAll}><img src={activateallslot? Redcross : Vector} alt="" /></button>:null}
        
    </div>
    )
}

export default Slot
