import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import HomeContainer from "../../../components/client/container/HomeContainer";
import { selectLanguage } from "../../../features/LanguageSlice";
import '../../../styles/search/General.scss'
import { SearchCard } from "./SearchCard";
import { Search } from "@mui/icons-material";
import SearchContainer from "../../../components/client/container/SearchContainer";
import { ConfirmCard } from "./nextCard";


export const  Booking = ()=>{
  const languageValue = useSelector(selectLanguage);
  let Title =
    languageValue === "En"
      ? "Confirm booking"
      : "Confirmez votre rendez-vous";


  return (
    <div className="change-container">
      <SearchContainer Title={Title}  display_map="false">
       <ConfirmCard/>
      </SearchContainer>
    </div>
  );
}

