import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const auth = localStorage.getItem("user_token");
  useEffect(() => {
    if (!auth) {
      return navigate("/");
    }
  });
  return <>{auth && children}</>;
};

export default ProtectedRoute;
