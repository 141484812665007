import React from "react";
import "../../../styles/client/common/Menu.scss";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Translate from "../../../config/Translate";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import CloseIcon from "@mui/icons-material/Close";
import LanguageSwitcher from "../../common/LanguageSwitcher";

const Menu = ({ setShowmenu }) => {
  const languageValue = useSelector(selectLanguage);
  const token = localStorage.getItem("user_token");
  const accountType = localStorage.getItem("user_accountType");
  const navigate = useNavigate();

  const menuchange = (menuName = "#") => {
    menuName != "#" && navigate(`/${menuName}`);
    setShowmenu();
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const backToBord = () => {
     if (accountType == 0) {
      navigate("/customer/dashboard/summary");
    } else if (accountType == 1) {
      navigate("/clinic/dashboard");
    }
  };

  return (
    <div className="main_menu_container">
      <div className="transparent_close" onClick={() => menuchange("#")}></div>
      <AnimatePresence>
        <motion.div
          className="menu-container"
          initial={{ opacity: 0, y: 0, x: 100 }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          <h1 onClick={() => menuchange("#")}>
            <CloseIcon />
          </h1>
          <ul>
            {/* <li><div className="action-btn  btn-hide">
          <LanguageSwitcher />
        </div>
           </li> */}
            {token ? (
              <>
                {/* <li onClick={() => menuchange("change")}>
                  {languageValue === "En"
                    ? "Change password"
                    : "Changer le mot de passe"}
                </li> */}
                <li onClick={handleLogout}>
                  {languageValue === "En" ? "Logout" : "Déconnexion"}
                </li>
                <li onClick={backToBord}>
                  {languageValue === "En" ? "Dashboard" : "Tableau de bord"}
                </li>
              </>
            ):<>
              <li onClick={() => menuchange("login")}>
                  <Translate Word={"header.login"} />
              </li>
              <li onClick={() => menuchange("insicription")}>
                <Translate Word={"header.Register"} />
              </li>
            </>
          
            
            }
            <li onClick={() => menuchange("search")}>
            Rechercher un vétérinaire
            </li>
          <li>
          <div className="flex-container brands-container menu-icon-sp">
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.instagram.com/oneclic.vet39/">
                  <i class="fa-brands fa-instagram" style={{ color:"white" }}></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.facebook.com/oneclic.vet">
                  <i class="fa-brands fa-facebook-f" style={{ color:"white" }}></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.linkedin.com/company/oneclic-vet/">
                  <i class="fa-brands fa-linkedin-in" style={{ color:"white" }}></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.youtube.com/channel/UC0O2a6--nHRwtez4misTMsA">
                  <i class="fa-brands fa-youtube" style={{ color:"white" }}></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://www.tiktok.com/@oneclic.vet?lang=fr">
                  <i class="fa-brands fa-tiktok" style={{ color:"white" }}></i>
                </Link>
              </div>
              <div className="flex-column brands-icon">
                <Link target="_blank" to="https://x.com/Oneclicvet">
                  <i class="fa-brands fa-x-twitter" style={{ color:"white" }}></i>
                </Link>
              </div>
            </div>
          </li>
          </ul>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Menu;
