import { useSelector } from "react-redux";
import Reset from "../../../components/client/account/Reset";
import ClientContainer from "../../../components/client/container/Container";
import { selectLanguage } from "../../../features/LanguageSlice";

const ResetPage = () => {
  const languageValue = useSelector(selectLanguage);

  let Title =
    languageValue === "En"
      ? "Mot de passe oublié ?"
      : "Mot de passe oublié ?";
  let Subtitle =
    languageValue === "En"
      ? "Réinitialisez votre mot de passe"
      : "Réinitialisez votre mot de passe";

  return (
    <div className="reset-container">
      <ClientContainer Title={Title} Subtitle={Subtitle}>
        <Reset />
      </ClientContainer>
    </div>
  );
};

export default ResetPage;
