import { useState } from "react";
import "../../../styles/client/common/TimeTable.scss";
import GridTimeTable from "./Timetable/Clinic/GridTimeTable";
import TimeTableSettings from "./Timetable/Clinic/Components/TimeTableSettings";

const TimeTable = () => {
  const [tab, setTab] = useState({ calendar: true, settings: false });

  return (
    <div className="content-container padding-special">
      <h1 className="welcome-title">
        Bienvenue <br />
        <span className="text_purple ">Horaires </span>en un clic
      </h1>
      <h1 className="page-name sm-page-name text_purple">
        Choisissez les horaires de votre établissement
      </h1>
      <div className="time_table_container">
        <div className="time_table">
          <div className="timetable_action">
            <div
              onClick={() => setTab({ calendar: true, settings: false })}
              className={tab.calendar && "active"}
            >
              Horaires de la semaine
            </div>
            <div
              onClick={() => setTab({ calendar: false, settings: true })}
              className={tab.settings && "active"}
            >
              Horaires spéciaux
            </div>
          </div>
          {tab.settings ? <TimeTableSettings /> : <GridTimeTable />}
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
