import { Link, useNavigate } from "react-router-dom";
import "../../../styles/client/account/Login.scss";
import House from "../../../assets/Icons/clinic_house.png";
import flag1 from "../../../assets/Icons/flag1.png";
import Google from "../../../assets/Icons/logo googleg 48dp.png";
import Facebook from "../../../assets/Icons/Facebook Logo.png";
import Apple from "../../../assets/Icons/Apple Logo.png";
import EditIcon from "../../../assets/Icons/edit_icon.png";
import flag2 from "../../../assets/Icons/flag2.png";
import flag3 from "../../../assets/Icons/flag3.png";
import flag4 from "../../../assets/Icons/flag4.png";
import tick from "../../../assets/Icons/Vector.png";
import calendar from "../../../assets/Icons/calendar_blue.png";
import time from "../../../assets/Icons/time_blue.png";
import { set, useForm } from "react-hook-form";
import { AXIOS_POST } from "../../../config/axios";
import { POST_GOOGLE_LOGIN, POST_LOGIN_URL } from "../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import Loader from "../../../helper/Loader/Loader";
import Translate from "../../../config/Translate";
import FormErrors from "../../../helper/Message/FormErrors";
import { authPop, loginWithApple, loginWithFacebook } from "../../../firebase";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { selectBookinginfo } from "../../../features/SearchSlice";
import { Base_Url } from "../../../config/variables";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { ValidateValue } from "../../../helper/Message/ValidateValue";

function Login() {
  const navigate = useNavigate();
  const bookingData = useSelector(selectBookinginfo).payload;
  const [isSubmitted, setisSubmitted] = useState(false);
  const [passwordvisible, setpasswordvisible] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.authType = 1;
    setisSubmitted(true);
    AXIOS_POST(POST_LOGIN_URL, data)
      .then((res) => {
        if(res.data?.isVerified == false){
          localStorage.setItem("user_email",data.email);
          ErrorToast(res?.data?.message);
          setTimeout(()=>navigate("/verify",{replace:true}),5000);
          return;
        }
        localStorage.setItem("user_token", res.data?.token);
        localStorage.setItem("user_accountType", res.data?.accountType);
        localStorage.setItem("user_id", res.data?.user._id);
        localStorage.setItem("user_email", res.data?.user.email);
        localStorage.setItem("user_firstname", res.data?.user.firstname? res.data?.user.firstname:"");
        localStorage.setItem("user_lastname", res.data?.user.lastname ? res.data?.user.lastname:"");
        localStorage.setItem("authType", "normal");
        setisSubmitted(false);
        if (res.data?.accountType === 0 && res.data?.isVerified) {
          setTimeout(
            () => {
              if (bookingData?.notes?.length > 0 && bookingData?.selectedDate && bookingData?.selectedTime && bookingData?.vet?._id && bookingData?.vet?.name ) {
                navigate("/search/confirm-booking",{replace:true});
              } else {
                navigate("/customer/dashboard/summary", { replace: true });
              }
            },
            0
          );
        } else if (res.data?.accountType === 1 && res.data?.isVerified) {
          localStorage.setItem("user_facilityName", res.data?.user?.facilityName);
          setTimeout(() => navigate("/clinic/dashboard/myclinicinfo", { replace: true }), 0);
        } else {
          localStorage.clear();
          setTimeout(() => navigate("/", { replace: true }), 0);
        }
      })
      .catch((error) => {
        setisSubmitted(false);
        ErrorToast(error);

      });
  };

  const googleAuth = async () => {
    const user = await authPop();
    let data = {};
    data.authType = 1;
    if (user?.accessToken?.length > 0) {
      AXIOS_POST(POST_GOOGLE_LOGIN, data, user.accessToken)
        .then((res) => {
          localStorage.setItem("user_token", res.data?.token);
          localStorage.setItem("user_accountType", res.data?.accountType);
          localStorage.setItem("user_id", res.data?.user._id);
          localStorage.setItem("user_email", res.data?.user.email);
          localStorage.setItem("user_firstname", res.data?.user.firstname ? res.data?.user.firstname : "");
          localStorage.setItem("user_lastname", res.data?.user.lastname ? res.data?.user.lastname : "");
          localStorage.setItem("authType", "google");
          setisSubmitted(false);
          if (res.data?.accountType === 0 && res.data?.isVerified) {
            setTimeout(
              () => {
                if (bookingData?.notes?.length > 0 && bookingData?.selectedDate && bookingData?.selectedTime && bookingData?.vet?._id && bookingData?.vet?.name ) {
                  navigate("/search/confirm-booking",{replace:true});
                } else {
                  navigate("/customer/dashboard/summary", { replace: true });
                }
              },
              0
            );
          } else if (res.data?.accountType === 1 && res.data?.isVerified) {
            setTimeout(
              () => navigate("/clinic/dashboard/myclinicinfo", { replace: true }),
              0
            );
          } else {
            localStorage.clear();
            setTimeout(() => navigate("/", { replace: true }), 0);
          }
        })
        .catch((error) => {
          ErrorToast(error);
          setisSubmitted(false);
        });
    }
  };
  // const facebookAuth =()=>{
  //   loginWithFacebook()
  // }

  // const appleAuth =()=>{
  //   loginWithApple()
  // } 

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="black login-wrapper">
        <h1 className="font-20">
          Vous avez déjà un <span className="main-color">oneclinic.net</span>
        </h1>
        <span className="main-color lg-size">Connexion</span>
        <h1 className="black">
          <Translate Word={"header.Login"} />
        </h1>
        <div className="login_action_container">
          <div className="section1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="lg-email">E-mail</label>
              <input
                id="lg-email"
                type="text"
                placeholder="Entrez votre adresse e-mail"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <FormErrors>
                  <Translate Word={"page.register.email.error"} />
                </FormErrors>
              )}
              <label htmlFor="lg-password">Mot de passe</label>
              <div className="vb_item ">
              <input
                id="lg-password"
                type={passwordvisible ? "text" : "password"}
                placeholder="Entrez votre mot de passe"
                {...register("password", { required: true })}
              />
              <div className="vb_icon " onClick={()=>setpasswordvisible(!passwordvisible)}>
                  {passwordvisible ? <VisibilityOutlined />:<VisibilityOffOutlined/>}
              </div>
              </div>
              
              {errors.password && (
                <FormErrors>
                  <Translate Word={"page.register.password.error"} />
                </FormErrors>
              )}

              {isSubmitted ? (
                <button type="button">
                  <Loader color="white" />
                </button>
              ) : (
                <button>
                  <Translate Word={"header.login"} />
                </button>
              )}
            </form>
            <div className="remember-forget">
              <div className="checkbox-group">
                <label className="checkbox_wrapper" htmlFor="checkbox1">
                  <div className="pet_checkbox"></div>
                  <span>Se souvenir de moi. </span>
                  <input
                    type="checkbox"
                    className="pet_hidden_check"
                    id="checkbox1"
                    hidden
                  />
                  <img src={tick} alt="" />
                </label>
              </div>
              <Link to="/forget" className="forget_password">
                <Translate Word={"page.forget.password"} />
              </Link>
            </div>

            {/* <button className="other-login other-login1" onClick={facebookAuth}> <img src={Facebook} alt="" />Se connecter avec Facebook</button> */}
            <button className="other-login other-login2" onClick={googleAuth}>
              {" "}
              <img src={Google} alt="" />
              Se connecter avec Google
            </button>
            {/* <button className="other-login other-login3" onClick={appleAuth}> <img src={Apple} alt="" />Se connecter avec Apple</button> */}
            <hr />
            <p>Nouveau sur Oneclic.vet ? <span className="main-color" onClick={()=>navigate("/insicription")}>Créer un compte</span></p>
          </div>

          {bookingData?.notes?.length > 0 && (
            <div className="section2">
              <div className="search-info">
                <div className="lert">
                  Votre rendez-vous n’est pas encore confirmé.
                </div>
                <div className="intro">
                  <div className="img-container">
                    <img
                      src={
                        bookingData?.vet?.picture
                          ? bookingData?.vet?.picture.includes("http")
                            ? bookingData.vet.picture
                            : `${Base_Url}/${bookingData?.vet?.picture}`
                          : "https://cdn.vectorstock.com/i/1000x1000/08/75/cute-yorkshire-terrier-dog-avatar-vector-20670875.webp%22"
                      }
                      alt="" 
                    />
                  </div>
                  <div className="details">
                    <h2> {bookingData.vet.name} </h2>
                    <h4> Vétérinaire </h4>
                    <div className="language">
                      {bookingData.vet.language.map((item, index) => (
                       <>
                        <img src={item == "French" && flag1} />
                        <img src={item == "English" && flag2} />
                        <img src={item == "German" && flag3} />
                        <img src={item == "Italian" && flag4} />
                       </>
                      ))}
                    </div> 
                  </div>
                </div>
                <div className="details-info">
                  <div className="vet-address">
                    <div className="logo">
                      <img src={House} />
                    </div>  
                    <div className="info">
                      <p className="address">Votre clinique :</p>
                    </div>
                  </div>
                  <div className="address-detail">
                    <p className="address1">
                      Clinique{" "}
                      <span>
                        {bookingData?.facilityName}
                      </span>
                    </p>
                    <p className="address2">
                    {<ValidateValue value={bookingData.address} />}{" "}
                          {bookingData.postalcode} {bookingData.city}- 
                          {<ValidateValue value={bookingData.country} />}
                    </p>
                  </div>
                </div>
                <div className="details-info">
                  <div className="vet-address">
                    <div className="info">
                      <p className="address">Date et heure du rendez-vous</p>
                    </div>
                  </div>
                  <div className="vet-address excpt">
                    <div className="logo">
                      <img src={calendar} /> 
                    </div>
                    <div className="info">
                      <p className="address">{bookingData.selectedDate}</p>
                    </div>
                  </div>
                  <div className="vet-address excpt">
                    <div className="logo">
                      <img src={time} />
                    </div>
                    <div className="info">
                      <p className="address">{bookingData.selectedTime}</p>
                    </div>
                  </div>
                </div>

                <button
                  className="button-edit"
                  onClick={() => navigate("/search/next-booking")}
                >
                  <img src={EditIcon} alt="" />
                  éditer
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
