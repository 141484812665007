import "../../../styles/client/common/Banner.scss";
import { motion, AnimatePresence } from "framer-motion";
import Fade from "react-reveal/Fade";

const Banner = ({ Title = "", Subtitle = "" }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="banner-container"
        initial={{ opacity: 1, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0 }}
      >
        <div className="banner_content">
          <Fade bottom>
            <h1>{Title}</h1>
          </Fade>
          <Fade bottom>
            <span>{Subtitle}</span>
          </Fade>
        </div>
        <div className="banner_layer"></div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Banner;
