import React, { useEffect } from "react";
import Add from "../../../../../../assets/Icons/add_pupple.png";
import Trash from "../../../../../../assets/Icons/Trash-icon-purple.svg";
import tick from "../../../../../../assets/Icons/Vector_pupple.png";
import { useState } from "react";
import {
  DELETE_SPECIAL_DAYS_URL,
  POST_SPECIAL_DAYS_URL,
  UPDATE_SPECIAL_DAYS_URL,
} from "../../../../../../helper/Url";
import { AXIOS_DELETE, AXIOS_POST, AXIOS_PUT } from "../../../../../../config/axios";
import SuccessToast from "../../../../../../helper/Message/SuccessToast";
import ErrorToast from "../../../../../../helper/Message/ErrorToast";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../../../helper/Loader/Loader";
import { set } from "react-hook-form";

const SettingTimeslot = ({ cid=null,cnumber, cdate = "", ctimeRanges = "",LoadData,deleteIndex,entId=null }) => {
  const [timeInput, settimeInput] = useState([
    { start: "", end: "", reason: "open", type: "open", allDay: false },
  ]);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const token = localStorage.getItem("user_token");
  const settingDate = (e) => {
    setDate(e.target.value);
  };

  useEffect(() => {
    if (cdate) {
      setDate(cdate);
    }
    if (ctimeRanges) {
      const timepassed = (ctimeRanges) => {
        const newTimeRanges = ctimeRanges.map((timeRange) => {
          const { start, end, reason, allDay } = timeRange;
          return {
            start: formatTime(start.hours, start.minutes),
            end: formatTime(end.hours, end.minutes),
            reason,
            allDay,
          };
        });
        return newTimeRanges;
      };
      settimeInput(timepassed(ctimeRanges));
    }
  }, [cdate, ctimeRanges]);

  function formatTimeSegment(segment) {
    const numericSegment = parseInt(segment, 10);
    if (numericSegment >= 0 && numericSegment <= 9) {
      return "0" + numericSegment;
    }
    return segment;
  }

  function formatTime(hours, minutes) {
    const formattedHours = formatTimeSegment(hours);
    const formattedMinutes = formatTimeSegment(minutes);
    return `${formattedHours}:${formattedMinutes}`;
  }

  const increment = () => {
    if ((timeInput[0].start && timeInput[0].end) ||  timeInput[0].allDay) {
      settimeInput((prev) => {
        return [{ start: "", end: "", reason: "", allDay: false }, ...prev];
      });
      document.querySelectorAll(".special-input-time").forEach((el) => {
        el.value = "";
      });
    } else {
      alert("Ajouter une heure de début et de fin");
    }
  };

  const decrement = (i) => {
    const newarray = timeInput.filter((ivl, index) => index != i);
    settimeInput(newarray);
  };
  const changeValue = (idx, e) => {
    const newarray = [...timeInput];
  
    if (e.target.name === "allDay") {
      newarray[idx][e.target.name] = e.target.checked;
  
      if (e.target.checked) {
        newarray[idx].start = "";
        newarray[idx].end = "";
      }
  
      settimeInput(newarray);
      return;
    }
  
    newarray[idx].allDay = false;
    newarray[idx][e.target.name] = e.target.value;
    settimeInput(newarray);
  }

  const sendSpecialday = () => {
    setLoading(true);
    const convertedTimeInput = timeInput
      .map((slot) => {
        if (slot.start && slot.end) {
          return {
            ...slot,
            start: convertToHoursMinutes(slot.start),
            end: convertToHoursMinutes(slot.end),
            type: slot.reason,
          };
        }
        if(slot.allDay){
          return {
            ...slot,
            start: { hours: 0, minutes: 0 },
            end: { hours: 23, minutes: 0 },
            type: slot.reason,
          };
        }
      })
      .filter((slot) => slot);
      if (date && convertedTimeInput.length>0) {
      const data = {};
      data.date = new Date(date).toISOString();
      data.entityId = entId;
      data.entityModel = "Service";
      data.timeRanges = convertedTimeInput;
      AXIOS_POST(POST_SPECIAL_DAYS_URL, data, token)
        .then((res) => {
          LoadData();
          deleteIndex(cnumber);
          setLoading(false);
          SuccessToast("Date spéciale ajoutée avec succès");
        })
        .catch((err) => {
          setLoading(false);
          if(err.response.data.message[0] ){
            ErrorToast(err.response.data.message[0]);
          }else{
            ErrorToast(err.response.data.message);
          }
        });
    } else {
      alert("Ajouter une date et une heure");
      setLoading(false);
    }
  };

  // update special day

  const updateSpecialday = () => {
    setLoading(true);
    const convertedTimeInput = timeInput
      .map((slot) => {
        if (slot.start && slot.end) {
          return {
            ...slot,
            start: convertToHoursMinutes(slot.start),
            end: convertToHoursMinutes(slot.end),
            type: slot.reason,
          };
        }
        if(slot.allDay){
          return {
            ...slot,
            start: { hours: 0, minutes: 0 },
            end: { hours: 23, minutes: 0 },
            type: slot.reason,
          };
        }
      })
      .filter((slot) => slot); 
    if (date && convertedTimeInput.length>0) {
      const data = {};
      data.date = new Date(date).toISOString();
      data.entityId = entId;
      data.entityModel = "Service";
      data.timeRanges = convertedTimeInput;
      AXIOS_PUT(UPDATE_SPECIAL_DAYS_URL + `/${cid}`, data, token)
        .then((res) => {
          setLoading(false);
          SuccessToast("Date spéciale modifiée avec succès");
        })
        .catch((err) => {
          setLoading(false);
          ErrorToast(err.response.data.message);
        });
    } else {
      alert("Ajouter une date et une heure");
      setLoading(false);
    }
  };


  const deleteSpecialday = () => {
    setDeleteLoading(true);
    AXIOS_DELETE(DELETE_SPECIAL_DAYS_URL + `/${cid}`,'',token)
      .then((res) => {
        LoadData();
        setDeleteLoading(false);
        SuccessToast("Date spéciale supprimée avec succès");
      })
      .catch((err) => {
        setDeleteLoading(false);
        ErrorToast(err.response.data.message);
      });
  };

  const convertToHoursMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return { hours: +hours, minutes: +minutes };
  };

  return (
    <div>
      <ToastContainer />
      <div className="input_detail gap120  mg-bm-50">
        <div className="date_section">
          <input type="date" value={date} onChange={settingDate} />
        </div>
        <div className="time_section">
          {timeInput.map((time, index) => (
            <div className="time_list " key={index}>
              <div className="input_detail align-start">
                <div className="reason">
                  <select
                    name="reason"
                    className="special-input-time"
                    id=""
                    onChange={(e) => changeValue(index, e)}
                    value={time.reason}
                  >
                    <option value="" selected disabled>
                      Choisissez une raison
                    </option>
                    <option value="close">Horaires de fermeture</option>
                    <option value="open">Horaires d’ouverture</option>
                  </select>
                  {time.reason === "close" && (
                    <div className="all-time">
                      <div className="checkbox_group">
                        <label
                          className="checkbox_wrapper"
                          htmlFor={`joul${cnumber}${index}`}
                          onChange={(e) => {
                            changeValue(index, e);
                          }}
                        >
                          <div className="pet_checkbox"></div>
                          <input
                            required
                            name="allDay"
                            type="checkbox"
                            className="pet_hidden_check"
                            id={`joul${cnumber}${index}`}
                            checked={time.allDay}
                            onChange={(e) => {
                              changeValue(index, e);
                            }}
                            hidden
                          />
                          {time.allDay && <img src={tick} alt="" />}
                        </label>
                      </div>
                      <span>Toute la journée</span>
                    </div>
                  )}
                </div>
                <div className="in_time_space time-box">
                  <input
                    className="text-center special-input-time"
                    name="start"
                    value={time.start}
                    onChange={(e) => changeValue(index, e)}
                    type={time.start === "" ? "text" : "time"}
                    onFocus={(e) => {e.target.type = "time"}}
                    onBlur={(e) => {if(e.target.value === ""){e.target.type = "text"}}}
                    placeholder="--:--"
                    step="3600"
                  />
                </div>
                <div className="time-box">
                  <input
                    className="text-center special-input-time"
                    name="end"
                    value={time.end}
                    onChange={(e) => changeValue(index, e)}
                    type={time.end === "" ? "text" : "time"}
                    onFocus={(e) => {e.target.type = "time"}}
                    onBlur={(e) => {if(e.target.value === ""){e.target.type = "text"}}}
                    placeholder="--:--"
                    step="3600"
                  />
                </div>
                <div className="action">
                  {index === 0 ? (
                    <div className="increment" onClick={increment}>
                      <img src={Add} alt="" />
                    </div>
                  ) : (
                    <div className="dicrement" onClick={() => decrement(index)}>
                      <img src={Trash} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="special-action">
        <div className="validate-date">
          {loading ? (
            <button className="btn btn-primary">
              <Loader color="white" />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={cid?updateSpecialday:sendSpecialday}>
              VALIDER LES MODIFICATIONS
            </button>
          )}
        </div>
        <div className="cancel-date">
          {deleteLoading ? (
            <button className="btn btn-primary">
              <Loader color="#8b7bcb" />
            </button>
          ) : cid &&
            <button className="btn btn-primary" onClick={deleteSpecialday}>
              ANNULER
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default SettingTimeslot;
