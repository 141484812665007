import React from "react";
import DogIcon from "../../../assets/Icons/dog-icon-p.png";
import GenderIcon from "../../../assets/Icons/gender-icon-p.png";
import CakeIcon from "../../../assets/Icons/cake-icon-p.png";
import NeuteIcon from "../../../assets/Icons/neutered-icon-p.png";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import {
  AXIOS_DELETE,
  AXIOS_GET,
  AXIOS_POST,
  AXIOS_PUT,
} from "../../../config/axios";
import {
  DELETE_PETS_URL,
  GET_SINGLE_PET_URL,
  POST_PETS_URL,
  UPDATE_PETS_URL,
} from "../../../helper/Url";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../helper/Loader/Loader";
import { Base_Url } from "../../../config/variables";

const AnimalDetail = () => {
  const userFirstname = localStorage.getItem("user_firstname");

  const token = localStorage.getItem("user_token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);

  const getAnimal = () => {
    AXIOS_GET(GET_SINGLE_PET_URL + `${id}`, token)
      .then((res) => {
        setFormData(res.data);
      })
      .catch((error) => {});
  };
  function calculatePetAge(birthdate) {
    const birthDateObject = new Date(birthdate);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const ageInMilliseconds = currentDate - birthDateObject;

    // Convert milliseconds to years, months, and days
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
    const years = Math.floor(ageInMilliseconds / millisecondsInYear);
    const remainingMilliseconds = ageInMilliseconds % millisecondsInYear;
    const months = Math.floor(
      remainingMilliseconds / (1000 * 60 * 60 * 24 * (365.25 / 12))
    );
    const days = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24));

    // Construct the age string
    let ageString = "";
    if (years > 0) {
      ageString += years + " ans ";
    }
    if (months > 0) {
      ageString += months + " mois ";
    }
    if (days > 0 && years === 0) {
      ageString += days + " jours ";
    }

    return ageString.trim();
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    formData.isInsured == "true"
      ? (formData.isInsured = true)
      : (formData.isInsured = false);
    setLoading(true);
    AXIOS_PUT(UPDATE_PETS_URL + `${id}`, formData, token)
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAnimal();
  }, [id]);

  return (
    <>
      {FormData?.name?.length > 0 ? (
        <div className="content-container">
          <h1 className="welcome-title">
            Bienvenue{" "}
          </h1>
          <h1 className="">
            La fiche de <span className="page-name text_purple">{formData?.name}</span> 
          </h1>
          <div className="pet-profile">
            <div className="profile-img-container border_purple">
              <img
                src={
                  formData?.picture
                    ? formData?.picture.includes("http")
                      ? formData.picture
                      : Base_Url + `/${formData?.picture}`
                    : "https://cdn1.vectorstock.com/i/1000x1000/08/65/cute-labrador-retriever-dog-avatar-vector-20670865.jpg"
                }
                alt=""
              />
            </div>
            <div className="profile-data">
              <ul>
                <li>
                  <img src={GenderIcon} alt="" />
                  <span>{formData?.sex}</span>
                </li>
                <li>
                  <img src={DogIcon} alt="" />
                  <span>{formData?.breed}</span>
                </li>
                <li>
                  <img src={CakeIcon} alt="" />
                  <span>{calculatePetAge(new Date(formData.birthdate)) || "0 ans"}</span>
                </li>
                <li>
                  <img src={NeuteIcon} alt="" />
                  <span>
                  {formData?.sex === "Femelle" && formData?.sterilize
                  ? "Stérilisée"
                  : formData?.sterilize && formData?.sex === "Mâle"
                  ? "Stérilisé"
                  : formData?.sex === "Femelle" ? "Non stérilisée" : "Non stérilisé"}
                  
                  </span>
                </li>
              </ul>
            </div> 
          </div>
          <div className="change-form">
            <form onSubmit={handleSubmit}>
              <h1 className="form-subtitle text_purple">
                Description de {formData?.name}
              </h1>

              <div
                className="flex-container gap-10"
                style={{ alignItems: "baseline" }}
              >
                <div className="input-container">
                  <h2 className="h2_fiche">
                    Nom de l'animal :{" "}
                    <span>
                      {formData.name ? formData.name : "Non communiqué"}
                    </span>
                  </h2>
                  <h2 className="h2_fiche">
                    Espèce de l’animal :{" "}
                    <span>
                      {formData.species
                        ? formData.species
                        : "Non communiqué"}
                    </span>
                  </h2>
                  <h2 className="h2_fiche">
                    Couleur de l’animal :{" "}
                    <span>
                      {formData.color ? formData.color : "Non communiqué"}
                    </span>
                  </h2>
                </div>
                <div className="input-container">
                  <h2 className="h2_fiche">
                    Race de l’animal :{" "}
                    <span>
                      {formData.breed ? formData.breed : "Non communiqué"}
                    </span>
                  </h2>
                  <h2 className="h2_fiche">
                    Date de naissance de l’animal :{" "}
                    <span>
                      {formData.birthdate
                        ? new Date(formData.birthdate)
                            .toISOString()
                            .slice(0, 10)
                        : "Non communiqué"}
                    </span>
                  </h2>
                </div>
              </div>
            </form>
            <form onSubmit={handleSubmit}>
              <h1 className="form-subtitle text_purple">
                Informations médicales de {formData?.name}
              </h1>
              <div
                className="flex-container gap-10"
                style={{ alignItems: "baseline" }}
              >
                <div className="input-container">
                  <h2 className="h2_fiche">
                    {" "}
                    Numéro de la puce de l’animal :{" "}
                    <span>
                      {formData.puceNumber
                        ? formData.puceNumber
                        : "Non communiqué"}
                    </span>
                  </h2>
                </div>
                <div className="input-container">
                  <h2 className="h2_fiche">
                    L’animal est-il assuré :
                    <span>{formData.isInsured ? "Oui" : "Non"}</span>
                  </h2>
                </div>
              </div>
              <div
                className="flex-container gap-10"
                style={{ alignItems: "baseline" }}
              >
                <div className="input-container">
                  <label htmlFor="" className="text_purple">
                    {" "}
                    vaccination
                  </label>
                  <h2 className="h2_fiche">
                    Date de la dernière vaccination :{" "}
                    <span>
                      {formData.nextVaccinationDate
                        ? new Date(formData.nextVaccinationDate)
                            .toISOString()
                            .slice(0, 10)
                        : "Non communiqué"}
                    </span>
                  </h2>
                </div>
                <div className="input-container">
                  <label htmlFor="" className="text_purple hidden">
                    Date de rappel du vaccin de la rage
                  </label>
                  <h2 className="h2_fiche">
                    Date de rappel du vaccin de la rage :{" "}
                    <span>
                      {formData.reminderDate
                        ? new Date(formData.reminderDate)
                            .toISOString()
                            .slice(0, 10)
                        : "Non communiqué"}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className="flex-container gap-10"
                style={{ alignItems: "baseline" }}
              >
                <div className="input-container">
                  <label htmlFor="" className="text_purple">
                    Antécédents médicaux
                  </label>

                  <h2 className="h2_fiche" style={{ fontWeight:400 }}>
                    {formData.medicalHistory
                      ? formData.medicalHistory
                      : "Non communiqué"}
                  </h2>
                </div>
                <div className="input-container">
                  <label htmlFor="" className="text_purple hidden">
                    L’animal a-t-il des pathologies ? Si oui, lesquelles ?
                  </label>
                  <h2 className="h2_fiche">
                  Pathologies de l’animal :{" "}
                    <span>
                    {formData.pathologies
                      ? formData.pathologies
                      : "Non communiqué"}
                    </span>
                  </h2>
                </div>
              </div>
            </form>
            <form onSubmit={handleSubmit}>
              <h1 className="form-subtitle text_purple">
                Mode de vie de {formData?.name}
              </h1>

              <div
                className="flex-container gap-10"
                style={{ alignItems: "baseline" }}
              >
                <div className="input-container">
                  <h2 className="h2_fiche">
                    Nourriture donnée à l’animal :{" "}
                    <span>
                      {formData?.allergies
                        ? formData.allergies
                        : "Non communiqué"}
                    </span>
                  </h2>
                </div>
                <div className="input-container">
                  <h2 className="h2_fiche">
                    L’animal sort-il ?{" "}
                    <span>{formData.isAnimalOut ? "Oui" : "Non"}</span>
                  </h2>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        "Pas encore de données"
      )}{" "}
    </>
  );
};

export default AnimalDetail;